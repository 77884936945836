import React from 'react';
import PanoImage from './PanoImage';
import NewMeeting1 from './NewMeeting1';
import ImageSlider2 from './ImageSlider2';
import { Helmet } from 'react-helmet';

const MainMeeting = () => {
  return (
    <div>
      <Helmet>
        <title>Meeting and Event Spaces in London - Bridge Park Hotel</title>
        <meta name="description" content="Host your next event in style at Bridge Park Hotel. Our versatile meeting and event spaces in London are perfect for conferences, meetings, and special occasions. Book today!" />
        <meta name="keywords" content="Meeting and Event Spaces London, conference rooms London, event venues London, Bridge Park Hotel meeting rooms, London event spaces, professional meeting facilities London" />
        <meta name="author" content="Bridge Park Hotel" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Meeting and Event Spaces in London - Bridge Park Hotel" />
        <meta property="og:description" content="Discover top-notch meeting and event spaces at Bridge Park Hotel in London. Perfect for conferences, meetings, and special events. Learn more and book your space now!" />
        <meta property="og:image" content="URL_to_image_of_event_space.jpg" /> {/* Replace with actual URL to an image of your meeting/event space */}
        <meta property="og:url" content="https://bridgeparkhotel.co.uk/main-meeting" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://bridgeparkhotel.co.uk/main-meeting" />
      </Helmet>
      <ImageSlider2 />
      <NewMeeting1 />
      <PanoImage />
    </div>
  );
}

export default MainMeeting;

import React from "react";
import "../../css/HouseRules.css";
import exportImg from "../../assets/icons/export.gif";
import cancelImg from "../../assets/icons/cancel.gif";
import childrenBed from "../../assets/icons/Children & Beds.gif";
import ageImg from "../../assets/icons/age-restriction.gif";
import pets from "../../assets/icons/Pets.gif";
import groupImg from "../../assets/icons/Group.gif";
import acceptPay from "../../assets/icons/Accepted payment methods.gif";
import refundabaleImg from "../../assets/icons/Refundable DAmage.gif";
import wheelchair from "../../assets/icons/wheelchair.gif";
import americanex from "../../assets/icons/american-express.svg";
import mastercard from "../../assets/icons/mastercard.svg";
import visa from "../../assets/icons/visa.svg";
import card from "../../assets/icons/Cash.png";
import { Helmet } from "react-helmet";
const HouseRules = () => {
  return (
    <div className="house-rules-container">
       <Helmet>
        <title>Home Rules at Bridge Park Hotel - Ensure a Pleasant Stay</title>
        <meta name="description" content="Review the home rules of Bridge Park Hotel to ensure a pleasant stay. Our guidelines cover essential information to help you have a comfortable and enjoyable experience." />
        <meta name="keywords" content="Bridge Park Hotel home rules, hotel guidelines, hotel policies, guest rules Bridge Park Hotel, London hotel rules" />
        <meta name="author" content="Bridge Park Hotel" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Home Rules at Bridge Park Hotel - Ensure a Pleasant Stay" />
        <meta property="og:description" content="Check out the home rules at Bridge Park Hotel. Our guidelines are designed to help you have a smooth and enjoyable stay. Learn more about our policies and expectations." />
        <meta property="og:image" content="URL_to_image_related_to_rules_or_hotel.jpg" /> {/* Replace with actual URL to an image related to your hotel's rules */}
        <meta property="og:url" content="https://www.bridgeparkhotel.co.uk/HomeRules" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.bridgeparkhotel.co.uk/HomeRules" />
      </Helmet>
      <div className="house-rules-title-container">
        <div className="house-rules-main-title">
          <h1 className="house-rules-title">House rules</h1>
        </div>
        <div className="house-rules-availability-button">
          <button
            className="availability-button"
            onClick={() =>
              (window.location.href =
                "https://direct-book.com/properties/bridgeparkdirect")
            }
          >
            See All Availability
          </button>
        </div>
      </div>
      <p className="house-rules-description">
        Bridge Park Hotel takes special requests - add in the next step!
      </p>
      <div className="house-rules-container-body">
        <table class="house-rules-table">
          <tr>
            <td>
              <img
                src={exportImg}
                alt="Check-in Icon"
                class="house-rules-icon"
              />
            </td>
            <td className="house-rules-info">Check-in</td>
            <td>
              <ul className="house-rules-table-ul">
                <li>
                  Standard check-in time is 14:00 on the day of arrival. You can
                  check in anytime after 14:00. Early check-in is subject to
                  availability.
                </li>
                <li>
                  Guests are required to show a photo identification upon check-in
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <img
                src={exportImg}
                alt="Check-out Icon"
                class="house-rules-icon-rotate"
              />
            </td>
            <td className="house-rules-info">Check-out</td>
            <td>
              Check-out time is before 11:00 am. Late check out is subject to
              availability.
            </td>
          </tr>
          <tr>
            <td>
              <img
                src={cancelImg}
                alt="Cancellation Icon"
                class="house-rules-icon"
              />
            </td>
            <td className="house-rules-info">Cancellation/Prepayment</td>
            <td>
              Cancellation and prepayment policies vary according to
              accommodation type. Please check what{" "}
              <a
                class="condition-link"
                href="https://direct-book.com/properties/bridgeparkdirect/policies?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&currency=GBP&checkInDate=2024-07-29&checkOutDate=2024-07-30&trackPage=yes"
                target="_blank"
                rel="noopener noreferrer"
              >
                conditions
              </a>{" "}
              may apply to each option when making your selection.
            </td>
          </tr>
          <tr>
            <td>
              <img
                src={refundabaleImg}
                alt="Refundable damage deposit Icon"
                class="house-rules-icon"
              />
            </td>
            <td className="house-rules-info">Refundable damage deposit</td>
            <td>
              A damage deposit of GBP 100 is required on arrival. That's about
              GBP 100. This will be collected by credit card. You should be
              reimbursed on check-out. Your deposit will be refunded in full via
              credit card, subject to an inspection of the property.
            </td>
          </tr>

          <tr>
            <td>
              <img
                src={childrenBed}
                alt="Childern & bed Icon"
                class="house-rules-icon"
              />
            </td>
            <td className="house-rules-info">Children and beds</td>
            <td className="house-rules-Details">
              <ul>
                <li className="house-rule-sub-topic">Child policies</li>
                <li>All age are welcome. Guest under 18 must check in with a parent or guardian.</li>
                <li>
                Children 13 years and above charged as adults for breakfast.
                </li>
                <li>
                  To see correct prices and occupancy information, please add
                  the number of children in your group and their ages to your
                  search.
                </li>
                <li className="house-rule-sub-topic">
                  Cot and Sofa Bed Request
                </li>
                <li>
                  Cot and sofa bed are available upon request only for rooms
                  that can accommodate them.
                </li>
              </ul>
            </td>
          </tr>
          

          <tr>
            <td>
              <img
                src={ageImg}
                alt="Age restriction Icon"
                class="house-rules-icon"
              />
            </td>
            <td className="house-rules-info">Age restriction</td>
            <td className="house-rules-Details">
              The minimum age for check-in is 18
            </td>
          </tr>

          <tr>
            <td>
              <img src={pets} alt="Pets Icon" class="house-rules-icon" />
            </td>
            <td className="house-rules-info">Pets</td>
            <td className="house-rules-Details">Pets are not allowed.</td>
          </tr>

          <tr>
            <td>
              <img src={groupImg} alt="Group Icon" class="house-rules-icon" />
            </td>
            <td className="house-rules-info">Groups</td>
            <td className="house-rules-Details">
              When booking more than 5 rooms, different policies and additional
              supplements may apply.
            </td>
          </tr>

          <tr>
            <td>
              <img
                src={acceptPay}
                alt="Accepted payment methods Icon"
                class="house-rules-icon"
              />
            </td>
            <td className="house-rules-info">Accepted payment methods</td>
            <td>
              {" "}
              <img
                src={americanex}
                alt="Accepted payment methods Icon"
                class="house-rules-icon"
              />
              <img
                src={mastercard}
                alt="Accepted payment methods Icon"
                class="house-rules-icon"
              />
              <img
                src={visa}
                alt="Accepted payment methods Icon"
                class="house-rules-icon"
              />
              <img
                src={card}
                alt="Accepted payment methods Icon"
                class="house-rules-icon"
              />
              
            </td>
          </tr>
          <tr>
            <td>
              <img
                src={wheelchair}
                alt="wheelchaire restriction Icon"
                class="house-rules-icon"
              />
            </td>
            <td className="house-rules-info">Not disabled-friendly</td>
            <td className="house-rules-Details">
            The property does not have facilities for disabled guests and there is no lift.
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default HouseRules;

import React, { useState } from "react";
import RoomCard from "../../components/HomeRoom/RoomCard";
import { Row, Col, Modal } from "antd";
import "./RoomInfo.css";
import Images from "../common/Images";

import RoomAll from "../Allrooms/AllRooms";


const Room = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [selectedCardTitle, setSelectedCardTitle] = useState(null);
  

  const handleViewMoreClick = (cardId, cardTitle) => {
    setSelectedCardId(cardId);
    setSelectedCardTitle(cardTitle);
    setIsModalVisible(true);
    
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedCardId(null);
    setSelectedCardTitle(null);
   
  };

  const roomData = [
    {
      id: 28,
      image: Images.room1[11],
      title: "Single Room",
      cardTitle: " SINGLE ROOM",
      roomPrice: "£50",
      NoOfGuests: "01",
      NoOfRooms : "01",
      cardId:"01",
    
    },
    {
      id: 29,
      image: Images.room2[5],
      title: "Double Room With Private Bathroom",
      cardTitle: "DOUBLE ROOM",
      roomPrice: "£63",
      NoOfGuests: "02",
      NoOfRooms : "01",
      cardId:"02",
    },
    {
      id: 34,
      image: Images.room3[2],
      title: "Twin Room With Shared Bathroom",
      cardTitle: "TWIN ROOM",
      roomPrice: "£68",
      NoOfGuests: "02",
      NoOfRooms : "01",
      cardId:"03",
    },
    {
      id: 11,
      image: Images.room4[3],
      title: "Triple Room With Private Bathroom",
      cardTitle: "TRIPLE ROOM",
      roomPrice: "£90",
      NoOfGuests: "02",
      NoOfRooms : "01",
      cardId:"04",
    },
    {
      id: 10,
      image: Images.room9[1],
      title: "Quadruple Room With Private Bathroom",
      cardTitle: "QUADRUPLE ROOM",
      roomPrice: "£110",
      NoOfGuests: "03",
      NoOfRooms : "01",
      cardId:"05",
    },
    {
      id: 5,
      image: Images.room11[4],
      title: "Executive King Room With Private Bathroom",
      cardTitle: "EXECUTIVE KING ROOM",
      roomPrice: "£115",
      NoOfGuests: "04",
      NoOfRooms : "01",
      cardId:"06",
    },
  ];

  return (
    <div className="rooms-main-container-R2">
      <div className="aboutContainer-R2">
        <div className="room-text-wraper-R2">
          {/* <h1 className="aboutH1">OUR ROOMS</h1> */}
          <div className='location-title'>Our Rooms</div>
          <div className="rooms-sub-content">
            <p>
              Originally a Grade II-listed building, Bridge Park Hotel is the ideal place to stay, whether you are visiting 
              Wembley or simply looking for accommodation to explore the sites of London. Just a short distance from 
              Wembley Stadium.
            </p>
          </div>
        </div>
      </div>
      <div className="hotelRoomMainDiv-R2">
        <Row gutter={[16, 16]} className="hotelRoom-row-R2">
          {roomData.map((room) => (
            <Col key={room.id} className="hotelRoom-col-R2">
              <RoomCard
                imageSource={room.image}
                price={room.roomPrice}
                cardtitle={room.cardTitle}
               
                onViewMoreClick={() => handleViewMoreClick(room.cardId, room.cardTitle)}
              />
            </Col>
          ))}
        </Row>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="80%"
        className="custom-modal"
      >
        {selectedCardId && selectedCardTitle && (
    <RoomAll 
      key={selectedCardId} 
      selectedCardId={selectedCardId} 
      selectedCardTitle={selectedCardTitle} 
    />
  )}
      </Modal>
    </div>
  );
};

export default Room;

import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd'; // Import DatePicker from Ant Design
import './siteCard.css'; // Import CSS file for styling
import moment from 'moment';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import updateLocale from 'dayjs/plugin/updateLocale';
import '@fortawesome/fontawesome-free/css/all.min.css';

dayjs.extend(weekday);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  weekdays: [
    "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
  ]
});

const SiteCard = ({ selectedRoomId,roomPrice,bookLink, vat,count_guest }) => {
  const [formData, setFormData] = useState({
    checkInDate: dayjs(),
    checkOutDate: dayjs(),
    adults: 1,
    children: 0,
    language: 'en',
    currency: 'USD',
  });

  const [daysOfWeek, setDaysOfWeek] = useState({
    checkInDay: dayjs().format('dddd'),
    checkOutDay: dayjs().format('dddd'),
  });

  
  const [tax, setTax] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showVat, setShowVat] = useState(false);

  const bookingEngineUrl = `https://direct-book.com/properties/bridgeparkdirect/book`;

  const handleCountChange = (fieldName, increment) => {
    setFormData(prevState => {
      const updatedValue = Math.max(0, prevState[fieldName] + increment);
      return {
        ...prevState,
        [fieldName]: updatedValue,
      };
    });
  };

  const handleDateChange = (date, fieldName) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: date
    }));

    if (fieldName === 'checkInDate') {
      setDaysOfWeek(prevState => ({
        ...prevState,
        checkInDay: date ? date.format('dddd') : 'Select the date'
      }));
    } else if (fieldName === 'checkOutDate') {
      setDaysOfWeek(prevState => ({
        ...prevState,
        checkOutDay: date ? date.format('dddd') : 'Select the date'
      }));
    }
  };
  const handleClearDate = (fieldName) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: null
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const queryString = new URLSearchParams({
      
      check_in_date: formData.checkInDate.format('YYYY-MM-DD'),
      check_out_date: formData.checkOutDate.format('YYYY-MM-DD'),
      // number_adults: formData.adults,
      // number_children: formData.children,

    });
    window.location.href = `${bookingEngineUrl}?items[0][adults]=${count_guest}&items[0][children]=0&items[0][rateId]=${bookLink}&${queryString.toString()}&step=step1`;
  };



  const calculatePrices = () => {
    const personCount = formData.adults + formData.children;
    let price = roomPrice;
  
    if (personCount > 2) {
      price *= 2; // Double the price if more than two persons
    }
  
    const vat = price * 0.15; // Assuming a VAT rate of 15%
    const basicPrice = price - vat;
  
    setTax(vat);
    setTotalPrice(price); // Total price includes VAT
  };

  useEffect(() => {
    valueCheck(selectedRoomId);
  }, [selectedRoomId]);

  useEffect(() => {
    console.log('selectedRoomId:', selectedRoomId);
    console.log('roomPrice:', roomPrice);
    console.log('booklink:', bookLink);
    console.log('no_of_guest:', count_guest);
    calculatePrices();
  }, [formData.adults, formData.children, roomPrice]);

  const toggleVatVisibility = () => {
    setShowVat(!showVat);
  };

  const currentDate = moment().format('DD MMM');
  const valueCheck = (value) => value > 0;
  return (
    <div className='app-container2-site'>
    <div className="card-body-site">
               <div className='td-site'>
                    <p className='check-head-site'>CHECK IN</p>
                    <div className='current-day-site'>
                    {daysOfWeek.checkInDay}, {formData.checkInDate.format('DD MMM YYYY')}
                    </div>
                    <div className="date-input-container-site">
                    <DatePicker
                        name="checkInDate"
                        value={formData.checkInDate}
                        onChange={(date) => handleDateChange(date, 'checkInDate')}
                        format="DD MMM"
                        placeholder="Select date"
                        className="custom-datepicker1"
                      />
                    </div>
                  </div>
                  <div className='td-site'>
                    <p className='check-head-site'>CHECK OUT</p>
                    
                    <div className='current-day-site'>
                    {daysOfWeek.checkOutDay}, {formData.checkOutDate.format('DD MMM YYYY')}
                    </div>
                    <div className="date-input-container-site">
                    <DatePicker
                        name="checkOutDate"
                        value={formData.checkOutDate}
                        onChange={(date) => handleDateChange(date, 'checkOutDate')}
                        format="DD MMM"
                        placeholder="Select date"
                        className="custom-datepicker1"
                      />
                    </div>
                  </div>
               
               
             
            <div className="button-site-div">
            <button className="button-site" type="submit" onClick={handleSubmit}>Modify</button>
            </div>
          </div>
      
          <div className='price-vat-txt'>
          <hr/>
          <p className='total-price-p'> Total Price <span className='price-site'>£{roomPrice ? roomPrice.toFixed(2): '0.00'}</span></p>
          includes taxes + fees
         
          <div onClick={toggleVatVisibility} className="vat-dropdown"> 
          <i className={`fa-solid fa-chevron-down ${showVat ? 'open' : ''}`}></i>
             {showVat && (
              <div className="vat-details">
               <div>
                 <span className='vat-list'>VAT - 20%</span>
                 <span className='price-site1'>£{vat ? vat : '0.00'}</span>
               </div>
              <div>
              <span className='vat-list'>Basic Price</span>
              <span className='price-site1'>£{(roomPrice - vat).toFixed(2)}</span>
              </div>
               </div>             
            )}
          </div>  
        </div>
      </div>
     

  );
}

export default SiteCard;

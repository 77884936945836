import React from 'react';
import { Button } from 'antd';
import "../../css/Card.css";

const PrimaryButton = ({ title, onClick }) => (
  <Button type="default" className='btnPrimary-check' onClick={onClick}><p>
    {title}
    </p>
  </Button>
);

export default PrimaryButton;

import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import './NewMeeting1.css';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import birthday from "../../assets/MEDIA & ASSESTS/Small Birthday Parties.jpg"
import gathering from "../../assets/MEDIA & ASSESTS/Gathering.jpg";
import meeting from "../../assets/MEDIA & ASSESTS/Meetings.jpg";




const cardsData = [
  { id: 1, images: [birthday], title: 'Birthday Parties', description: 'Description 1', date: '2024-01-01', time: '07.00 pm' },
  { id: 2, images: [gathering],       title: 'Gathering', description: 'Description 2', date: '2024-02-01', time: '07.00 pm' },
  { id: 3, images: [ meeting], title: 'Meetings', description: 'Description 3', date: '2024-03-01', time: '07.00 pm' },
  
];

const NewMeeting1 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const imageInterval = setInterval(() => {
      handleNextImage();
    }, 1500);

    return () => clearInterval(imageInterval);
  }, [currentIndex]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % cardsData.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + cardsData.length) % cardsData.length);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % cardsData[currentIndex].images.length);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const visibleCards = [
    cardsData[(currentIndex - 1 + cardsData.length) % cardsData.length],
    cardsData[currentIndex],
    cardsData[(currentIndex + 1) % cardsData.length],
  ];

  return (
    <>
      <div className='location-title new-meeting-mtitle'>MEETING & EVENTS</div>
      <div className='pano-image-discription'>
        <hr className="pano-image-title-line" />
        <div className="about-sub-content"><p>
        Enhance your event with us! Our versatile meeting room comfortably accommodates 40–50 guests, making it ideal for business meetings or intimate gatherings. We also specialise in hosting small celebrations, from birthday parties and tea parties to anniversaries, cocktail events, and surprise parties. For outdoor occasions, our delightful beer garden provides the perfect setting, with on-site parking for added convenience.
        </p>
          </div>
      </div>
      <div className="new-meeting-card-carousel" {...handlers}>
        <button className="new-meeting-nav-button" onClick={handlePrev}><LeftOutlined /></button>
        <div className="new-meeting-cards-container">
          {visibleCards.map((card, index) => (
            <div key={card.id} className={`new-meeting-card ${index === 1 ? 'new-meeting-active' : 'new-meeting-non-active'}`}>
              <img src={card.images[currentImageIndex]} alt={card.title} />
              <div className={`new-meeting-card-content ${index === 1 ? 'new-meeting-active-content' : 'new-meeting-overlay-content'}`}>
                <h4 className='new-meeting-title'>{card.title}</h4>
              </div>
            </div>
          ))}
        </div>
        <button className="new-meeting-nav-button" onClick={handleNext}><RightOutlined /></button>
      </div>
    </>
  );
};

export default NewMeeting1;

import React, { useState, useEffect } from 'react';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import './PanoImage.css';
import Images from '../../components/common/Images';



const PanoImage = () => {
  const images = [
    { url: Images.image_360[0], emlink: 'https://momento360.com/e/u/fa86264e6c2c43a8a7b95de5ae66cc1c?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium&display-plan=true', alt: 'Image 5' },
    { url: Images.image_360[1], emlink: 'https://momento360.com/e/u/e9c3494e1c9b4f2fab06ee0318fc1d30?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium&display-plan=true', alt: 'Image 2' },  
    { url: Images.image_360[5], emlink: 'https://momento360.com/e/u/aaf2620bd43442dea53b59a66add4f29?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium&display-plan=true', alt: 'Image 2' },
    { url: Images.image_360[6], emlink: 'https://momento360.com/e/u/254ab25c6fc741249dc684958b224dd0?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium&display-plan=true', alt: 'Image 2' },
    { url: Images.image_360[4], emlink: 'https://momento360.com/e/u/00d1dda6b4e944e582588fce6d21482e?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium&display-plan=true', alt: 'Image 2' },
    { url: Images.image_360[7], emlink: 'https://momento360.com/e/u/3b7062054f8743cf941821f2dc13eed0?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium&display-plan=true', alt: 'Image 2' },
    { url: Images.image_360[2], emlink: 'https://momento360.com/e/u/ba800be35a8e4412923e7e967500718f?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium&display-plan=true', alt: 'Image 2' },
    { url: Images.image_360[3], emlink: 'https://momento360.com/e/u/8fb4440ba285423e99c9230361de7d61?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium&display-plan=true', alt: 'Image 2' },


  
    
  ];

  const [startIndex, setStartIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [visibleImages, setVisibleImages] = useState(window.innerWidth <= 820 ? 1 : 3);

  useEffect(() => {
    const handleResize = () => {
      setVisibleImages(window.innerWidth <= 768 ? 1 : 3);
      setStartIndex(0); // Reset startIndex to avoid out-of-bounds issues
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePrevClick = () => {
    setStartIndex(Math.max(startIndex - 1, 0));
  };

  const handleNextClick = () => {
    setStartIndex(Math.min(startIndex + 1, images.length - visibleImages));
  };

  const handleImageClick = (index) => {
    setSelectedImage(images[startIndex + index]);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className='pano-image-main'>
      <div className='location-title'>Experience Bridge Park Hotel Like Never Before</div>
      <div className='pano-image-discription'>
        <hr className="pano-image-title-line" />
        <p>Our 360° virtual tour provides an immersive look into our elegant rooms, stunning facilities, and scenic surroundings.</p>
      </div>
      <div className='pano-image-main2'>
        <button onClick={handlePrevClick} className={`pano-image-nav-btn-pre ${startIndex > 0 ? 'pano-image-active-btn' : 'pano-image-inactive-btn'}`}>
          <LeftOutlined style={{ fontSize: 25, color: '#08c' }} />
        </button>
        <div className="pano-image-image-gallery">
          {images.slice(startIndex, startIndex + visibleImages).map((image, index) => (
            <div key={index} className={`pano-image-image-container ${index === 1 ? 'pano-second-image-style' : ''}`} onClick={() => handleImageClick(index)}>
              <img src={image.url} alt={image.alt} />
            </div>
          ))}
        </div>
        <button onClick={handleNextClick} className={`pano-image-nav-btn-nxt ${startIndex + visibleImages < images.length ? 'pano-image-active-btn' : 'pano-image-inactive-btn'}`}>
          <RightOutlined style={{ fontSize: 25, color: '#08c' }} />
        </button>
      </div>
      {selectedImage && (
        <div className="pano-image-modal">
          <button onClick={handleCloseModal} className="pano-image-close-btn">
            &times;
          </button>
          <iframe
            title="360 degree panorama"
            width="100%"
            height="100%"
            src={selectedImage.emlink}
            allowFullScreen=""
            frameBorder="0"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default PanoImage;

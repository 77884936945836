import React, { useState } from 'react';
import { Carousel } from 'antd';
import './NewAbout.css';

import img2 from '../../assets/images/image_gellery/image1.webp';
import img3 from '../../assets/images/image_gellery/image2.webp';
import img4 from '../../assets/images/image_gellery/image3.webp';
import img7 from '../../assets/images/image_gellery/widen-1680-noupsize.webp';
import img8 from '../../assets/images/image_gellery/Wembley-Stadium-3.webp';

// Free stock images for the first carousel
import stockImg1 from '../../assets/images/image_gellery/image4.webp';
import stockImg2 from '../../assets/images/image_gellery/image5.webp';
import stockImg3 from '../../assets/images/image_gellery/image6.webp'
import stockImg4 from '../../assets/images/image_gellery/9.jpg'
import stockImg5 from '../../assets/images/image_gellery/10.jpg'


const NewAbout = () => {
  const stockImages = [stockImg1, stockImg2, stockImg3,stockImg4,stockImg5]; // New images for the first carousel
  const allImages = [ img2, img3, img4,img7, img8];
  const [currentSlide, setCurrentSlide] = useState(0);

  const rotateImages = (startIndex) => {
    return allImages.slice(startIndex).concat(allImages.slice(0, startIndex));
  };

  const carousels = [
    stockImages,        // Carousel 1 with different stock images
    rotateImages(2),    // Carousel 2 starts with the 3rd image
    rotateImages(4),    // Carousel 3 starts with the 5th image
    rotateImages(6)     // Carousel 4 starts with the 7th image
  ];

  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    effect: 'fade', // Use fade effect
    beforeChange: (current, next) => setCurrentSlide(next), // Update the active indicator
    dots: false, // Disable the default dots
  };

  return (
    <div className='new-about-main'>
      <div className='new-about'>
        {carousels.map((images, i) => (
          <div key={i} className={`carousel carousel${i + 1}`}>
            <Carousel {...settings}>
              {images.map((img, index) => (
                <div key={index}>
                  <img src={img} alt={`Slide ${index}`} className='carousel-image' />
                </div>
              ))}
            </Carousel>
            <div className="carousel-indicators">
              {images.map((_, index) => (
                <div
                  key={index}
                  className={`line ${currentSlide === index ? 'active' : ''}`}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewAbout;

const UrlLib = [
    //Dorm -room
    {
      id: 1,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=31919https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=1&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31919&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no ",
    },
    //Double w Shared bathroom
    {
      id: 2,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31921&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Double with Shared bathroom
    {
      id: 3,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31921&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Executive King room
    {
      id: 4,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31922&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Quad Ensuite
    {
      id: 5,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=4&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31923&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Quad Ensuite (Doble+bunk)
    {
      id: 6,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=4&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31925&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //(3 Single Beds)Quad Shared w bathroom (twins + bunk)Triple Room with Shared Bathroom
    {
      id: 7,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=4&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31927&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Single w Shared bathroom
    {
      id: 8,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=1&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31929&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Triple En suite with 3 Single Beds
    {
      id: 9,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=3&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31931&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Triple Room with Shared Bathroom 
    {
      id: 10,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=3&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31932&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Twin Ensuite
    {
      id: 11,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31933&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Twin w Shared bathroom
    {
      id: 12,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31934&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Double EnsuiteNon-Refundable - Room Only
    {
      id: 13,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&items[0][rateId]=448374&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Double EnsuiteNon-Refundable - Breakfast Included
    {
      id: 14,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&items[0][rateId]=448418&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Double EnsuiteFlexible - Room Only
    {
      id: 15,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&items[0][rateId]=448419&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Executive King RoomNon-Refundable - Room Only
    {
      id: 16,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&items[0][rateId]=448426&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Double EnsuiteFlexible - Room Only
    {
      id: 17,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&items[0][rateId]=448419&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Double w Shared bathroomNon-Refundable - Room Only
    {
      id: 18,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&items[0][rateId]=448420&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Quad Ensuite (Double + bunk)Flexible - Room Only
    {
      id: 19,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=4&items[0][children]=0&items[0][infants]=0&items[0][rateId]=448533&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Quad Ensuite (Double + bunk)Non-Refundable - Breakfast Included
    {
      id: 20,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=4&items[0][children]=0&items[0][infants]=0&items[0][rateId]=448444&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Quad Ensuite (Double + bunk)Non-Refundable - Room Only
    {
      id: 21,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=4&items[0][children]=0&items[0][infants]=0&items[0][rateId]=448442&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Quad EnsuiteFlexible - Room Only
    {
      id: 22,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=4&items[0][children]=0&items[0][infants]=0&items[0][rateId]=448440&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Quad EnsuiteNon-Refundable - Breakfast Included
    {
      id: 23,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=4&items[0][children]=0&items[0][infants]=0&items[0][rateId]=448438&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Quad EnsuiteNon-Refundable - Room Only
    {
      id: 24,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=4&items[0][children]=0&items[0][infants]=0&items[0][rateId]=448436&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Executive King RoomNon-Refundable - Breakfast Included
    {
      id: 25,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&items[0][rateId]=448427&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Double w Shared bathroomFlexible - Room Only
    {
      id: 26,
      url: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&items[0][rateId]=448425&checkInDate=2024-05-20&checkOutDate=2024-05-21&currency=GBP&trackPage=no",
    },
    //Double Ensuite
    {
      id:27,
      url:"https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31920&checkInDate=2024-06-28&checkOutDate=2024-06-29&currency=GBP&trackPage=yes",
    }
    ,
    //SINGLE ROOM BASIC
    {
      id:28,
      url:"https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=1&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31929&checkInDate=2024-06-29&checkOutDate=2024-06-30&currency=GBP&trackPage=yes  ",
    }
    ,
    //DOUBLE BASIC
    {
      id:29,
      url:"https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31921&checkInDate=2024-06-29&checkOutDate=2024-06-30&currency=GBP&trackPage=yes ",
    }
    ,
    //TRIPLE ENSUITE
    {
      id:30,
      url:"https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=3&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31932&checkInDate=2024-06-29&checkOutDate=2024-06-30&currency=GBP&trackPage=yes ",
    }
    ,
    //Triple En suite with 3 Single Beds
    {
      id:31,
      url:"https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=3&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31931&checkInDate=2024-06-29&checkOutDate=2024-06-30&currency=GBP&trackPage=yes ",
    }
    ,
    //Quad Ensuite
  
    {
      id:32,
      url:"https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=4&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31923&checkInDate=2024-06-29&checkOutDate=2024-06-30&currency=GBP&trackPage=yes ",
    }
    ,
    //Quad Ensuite (Double + bunk)
  
    {
      id:33,
      url:"https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=4&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31925&checkInDate=2024-06-29&checkOutDate=2024-06-30&currency=GBP&trackPage=yes ",
    }
    ,
    //TWIN ROOM WITH SHARED BATHROOM
    {
      id:34,
      url:"https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&items[0][rateId]=31934&checkInDate=2024-06-29&checkOutDate=2024-06-30&currency=GBP&trackPage=no",
    }
  ];
  
  export default UrlLib;
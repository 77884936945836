import React, { useState ,useEffect} from 'react';
import { IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import './ImageSlider2.css'; 
import Images from '../../components/common/Images';

const ImageSlider2 = () => {
  const images =   Images.image_gallery;


  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const getSlideClass = (index) => {
    if (index === currentIndex) return 'large';
    if (index === (currentIndex + 1) % images.length) return 'medium';
    if (index === (currentIndex + 2) % images.length) return 'small';
    return 'hidden';
  };
  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="imagesilder2-container">
      <IconButton onClick={goToPrevious} className="arrow left-arrow"
        style={{ color: 'rgb(0, 238, 255)' }} // Set the arrow color/>
        >
        <ArrowBackIos />
      </IconButton>
      <div className="imagesilder2-carousel">
        {images.map((image, index) => (
          <div key={index} className={`slide ${getSlideClass(index)}`}>
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </div>
      <IconButton onClick={goToNext} className="arrow right-arrow"
        style={{ color: 'rgb(0, 238, 255)' }} // Set the arrow color/>
        >
        <ArrowForwardIos 
        />
      </IconButton>
    </div>
  );
};

export default ImageSlider2;

import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "antd";
import Card from "../common/Card";
import "../../css/EnsuiteRooms.css";
import RoomInfromation from "../home/RoomInformation";
import personIcon1 from "../../assets/icons/people.png";
import personIcon2 from "../../assets/icons/two.png";
import personIcon3 from "../../assets/icons/Three.png";
import bedIcon1 from "../../assets/icons/bed.png";
import bedIcon2 from "../../assets/icons/rest.png";
import UrlLib from "../common/UrlLib";
import HouseRules from "../common/HomeRules";
import HotelRoomCardBasicRoom from "../Room/HotelRoomCardBasicRoom";
import HomeCard from "../Sitecard/homeCard";
import Images from "../common/Images";

const EnsuiteRoom = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [bookingUrl, setBookingUrl] = useState("");
  const [selectedRoomId, setSelectedRoomId] = useState(0);
  const [bathroomType, setBathroomType] = useState('');
  const [roomPrice, setRoomPrice] = useState('');
  const [bookLink, setBookLink] = useState('');
  const [count_guest, setCount_guest] = useState('');
  const [facilities, setFacilities] = useState([]);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  const handleViewMoreClick = (EnsuiteRoom, url ,id) => {
    setSelectedRoom(EnsuiteRoom);
    setBookingUrl(url);
    setIsModalVisible(true);
    setSelectedRoomId(id);
    setRoomPrice(EnsuiteRoom.roomPrice);
    setBookLink(EnsuiteRoom.bookLink);
    setFacilities(EnsuiteRoom.facilities || []);
    setCount_guest(EnsuiteRoom.count_guest)
    if (EnsuiteRoom.title.includes('SHARED')) {
      setBathroomType('SHARED');
    } else {
      setBathroomType('PRIVATE');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getUrlById = (id) => {
    const urlObject = UrlLib.find((url) => url.id === id);
    return urlObject ? urlObject.url : "#";
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const EnsuiteroomData = [
    {
      id: 27,
      image: Images.room5[0],
      title: "DOUBLE ROOM WITH PRIVATE BATHROOM",
      icon1: personIcon1,
      icon2: bedIcon1,
      cardTitle: "1 x DOUBLE BED",
      imageUrl: getUrlById(14),
      EroomPrice: "£73",
      vat:"14.6",
      NoOfGuests: "02",
      images: Images.room5,
      BookingLink:"31920",
      facilities: ["Free WiFi","Non-smoking rooms","Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],
      Disc:" Spacious and contemporary room with a comfortable double bed and a private bathroom. Situated less than a 10-minute travel to Wembley Stadium, it offers easy access to various transport links around London. Features include tea and coffee making facilities, TV with free-view, and free WiFi.",
    },
    {
      id: 11,
      image: Images.room6[0],
      title: "TWIN ROOM WITH PRIVATE BATHROOM",
      icon1: personIcon2,
      icon2: bedIcon2,
      cardTitle: "2 x SINGLE BEDS",
      imageUrl: getUrlById(11),
      EroomPrice: "£78",
      vat:"15.6",
      NoOfGuests: "02",
      images:Images.room6,
      BookingLink:"31933",
      facilities: ["Free WiFi","Non-smoking rooms","Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],
      Disc:"Spacious twin room with two single beds and private en-suite bathroom facilities. Conveniently located less than a 10-minute travel to Wembley Stadium, this room features contemporary amenities including tea and coffee making facilities, TV with free-view, and free WiFi.",
    },
    {
      id: 31,
      image: Images.room7[0],
      title: "TRIPLE ROOM WITH PRIVATE BATHROOM",
      icon1: personIcon2,
      icon2: bedIcon2,
      cardTitle: "3 x SINGLE BEDS",
      imageUrl: getUrlById(9),
      EroomPrice: "£100",
      vat:"20",
      NoOfGuests: "03",
      images: Images.room7,
       BookingLink:"31927",
       facilities: ["Free WiFi","Non-smoking rooms", 'Family rooms',"Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],
       Disc:" Spacious room with three single beds and private en-suite bathroom facilities. This room includes tea and coffee making facilities, TV with free-view, and free WiFi. Table and chairs are also available.",
    },
    {
      id: 30,
      image:  Images.room8[0],
      title: "TRIPLE ROOM WITH PRIVATE BATHROOM",
      icon1: personIcon3,
      icon2: bedIcon2,
      cardTitle: "1 x DOUBLE BED + 1 x SINGLE BED ",
      imageUrl: getUrlById(10),
      EroomPrice: "£105",
      vat:"21",
      NoOfGuests: "03",
      images: Images.room8,
      BookingLink:"31932",
      facilities: ["Free WiFi","Non-smoking rooms",'Family rooms',"Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],
       Disc:"This spacious room features one double bed and one single bed with private en-suite bathroom facilities. Located less than a 10-minute travel to Wembley Stadium, it offers easy access to various transport links around London. Includes tea and coffee making facilities, TV with free-view, and free WiFi. Table and chairs are available.",
    },
    {
      id: 32,
      image: Images.room10[2],
      title: "QUADRUPLE ROOM WITH PRIVATE BATHROOM",
      icon1: personIcon3,
      icon2: bedIcon2,
      cardTitle: "1 x DOUBLE BED + 1 x BUNK BED",
      imageUrl: getUrlById(10),
      EroomPrice: "£110",
      vat:"22", 
      NoOfGuests: "04",
       facilities: ["Free WiFi","Non-smoking rooms", 'Family rooms',"Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],
      images:Images.room10,
       BookingLink:"31925",
       Disc:"Spacious room with one double bed and a bunk bed, featuring private en-suite bathroom facilities. Located less than a 10-minute travel to Wembley Stadium, this room offers easy access to various transport links around London. Includes tea and coffee making facilities, TV with free-view, and free WiFi. Table and chairs are available.",
    },
    {
      id: 33,
      image:  Images.room9[1],
      title: "QUADRUPLE ROOM WITH PRIVATE BATHROOM",
      icon1: personIcon3,
      icon2: bedIcon2,
      cardTitle: "1 x DOUBLE BED + 2 x SINGLE BEDS",
      imageUrl: getUrlById(10),
      EroomPrice: "£125",
      vat:"25",
      NoOfGuests: "04",
       facilities: ["Free WiFi","Non-smoking rooms", 'Family rooms',"Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],
      images: Images.room9,
        BookingLink:"31923",
       Disc:" Spacious room featuring one double bed and two single beds with private en-suite bathroom facilities. Conveniently located, this room includes tea and coffee making facilities, TV with free-view, and free WiFi. Table and chairs are also available.",
    },

    {
      id: 4,
      image:  Images.room11[4],
      title: "EXECUTIVE KING ROOM WITH PRIVATE BATHROOM",
      icon1: personIcon3,
      icon2: bedIcon2,
      cardTitle: "1 x SOFA BED + 1 x LARGE DOUBLE BED",
      imageUrl: getUrlById(10),
      EroomPrice: "£115",
      vat:"23",
      NoOfGuests: "03",
      images: Images.room11,
       BookingLink:"448426",
       facilities: ["Free WiFi","Non-smoking rooms", 'Family rooms',"Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],
       Disc:" Stunningly spacious room featuring a luxurious king-sized bed and a sofa bed, with private en-suite bathroom facilities. This newly decorated suite at The Bridge Park Hotel offers a blend of elegance and comfort, ideal for special occasions. Includes tea and coffee making facilities, TV with free-view, and free WiFi. Additional amenities include air conditioning, bathrobes, and a mini fridge.",
    },
    {
      id: 4,
      image: Images.room12[1],
      title: "CHAMBRE OF TWILIGHT ROOM WITH PRIVATE BATHROOM",
      icon1: personIcon3,
      icon2: bedIcon2,
      cardTitle: "1 x SUPERIOR KING BED",
      imageUrl: getUrlById(10),
      EroomPrice: "£120",
      vat:"24", 
      NoOfGuests: "02",
      images:Images.room12,
       BookingLink:"",
       facilities: ["Free WiFi","Non-smoking rooms", 'Family rooms',"Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],
       Disc:"Stunningly spacious room featuring a luxurious superior king bed and private en-suite bathroom facilities. This newly decorated suite at The Bridge Park Hotel offers a blend of elegance and comfort, ideal for special occasions. Includes tea and coffee making facilities, TV with free-view, and free WiFi. Additional amenities include air conditioning, bathrobes, and a mini fridge.",
    },
  ];

  return (
    <div>
      <div className="EnsuiteRoom-cardContainor">
        {isMobile ? (
          <div className="EnsuiteRoom-sitecard">
             {/* <SiteCard selectedRoomId={selectedRoomId} /> */}
             <HomeCard />
          </div>
        ) : null}
        {!isMobile ? (
          <div className="EnsuiteRoom-card">
            <Card title="Card Title 2" description="Description for Card 2" />
          </div>
        ) : null}
      </div>
    
    <div className="EnsuiteRoom-Main-container">
      <div className="EnsuiteRoom-Container">
        <div className="EnsuiteRoom-TextWrapper">
        <div className="about-sub-content">
             <h3 style={{textAlign:'center'}}>Special Offer</h3>
              <p style={{textAlign:'center'}}>Book now for exclusive discounted online rates. Whether you're making a last minute booking or a long stay reservation, we have a discount for you! Book through our secure website for instant confirmation. Then use our online portal to view, modify or cancel your booking.</p>
        </div>
          <h1 className="EnsuiteRoom-heading">ENSUITE ROOMS</h1>
        </div>
        <div className="EnsuiteRoomMainDiv">
          <Row gutter={[16, 16]} className="EnsuiteRoom-row">
            {EnsuiteroomData.map((Ensuiteroom) => (
              <Col key={Ensuiteroom.id} className="EnsuiteRoom-col">
                <HotelRoomCardBasicRoom
                  imageSource={Ensuiteroom.image}
                  title={Ensuiteroom.title}
                  icon1={Ensuiteroom.icon1}
                  icon2={Ensuiteroom.icon2}
                  description="Spacious room with a breathtaking view"
                  guests={2}
                  status="Check"
                  price={Ensuiteroom.EroomPrice}
                  buttonText="Book Now"
                  cardtitle={Ensuiteroom.cardTitle}
                  NoOfGuests={Ensuiteroom.NoOfGuests} 
                  onViewMoreClick={() =>
                    handleViewMoreClick(
                      {
                        title: Ensuiteroom.title,
                        imageSource: Ensuiteroom.image,
                        id: Ensuiteroom.id,
                        cardTitle:Ensuiteroom.cardTitle,
                        images: Ensuiteroom.images,
                        Vat: Ensuiteroom.vat,
                        roomPrice: Ensuiteroom.EroomPrice,
                        bookLink:Ensuiteroom.BookingLink,
                        count_guest:Ensuiteroom.NoOfGuests,
                        disc:Ensuiteroom.Disc,
                        facilities: Ensuiteroom.facilities,
                      },
                      Ensuiteroom.imageUrl,Ensuiteroom.id
                    )
                  }
                  link={Ensuiteroom.imageUrl}
                />
              </Col>
            ))}
          </Row>
        </div>
        <HouseRules />
        <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width="65%"
        >
          <RoomInfromation
           room={selectedRoom}
            bookingUrl={bookingUrl}
             selectedRoomId={selectedRoomId}
             bathroomType={bathroomType}
             roomPrice={roomPrice}
             bookLink={bookLink}
             count_guest={count_guest}
             facilities={facilities}
             />
        </Modal>
      </div>
    </div>
    </div>
  );
};

export default EnsuiteRoom;
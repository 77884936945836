import React from 'react';
import './facilitiesiconsContainer.css';

import nonSmokingGif from '../../assets/Facilities of BPH/non_smoking_rooms.png';
import roomServiceGif from '../../assets/Facilities of BPH/room_service.png';
import wifiGif from '../../assets/Facilities of BPH/wifi.png';
import parkingGif from '../../assets/Facilities of BPH/parking.png';
import familyRoomsGif from '../../assets/Facilities of BPH/family.png';
import frontDeskGif from '../../assets/Facilities of BPH/hour.png';
import barGif from '../../assets/Facilities of BPH/bar.png';
import heatingGif from '../../assets/Facilities of BPH/heating.png';
import housekeepingGif from '../../assets/Facilities of BPH/Housekeeping.png';
import breakfastGif from '../../assets/Facilities of BPH/breakfast.png';

const facilityIcons = {
  "Free WiFi": wifiGif,
  "Non-smoking rooms": nonSmokingGif,
  "Daily housekeeping": housekeepingGif,
  "Private bathroom": roomServiceGif,
  'Free parking': parkingGif,
  'Family rooms': familyRoomsGif ,
  '24-hour front desk': frontDeskGif,
  'Bar': barGif,
  'Heating': heatingGif,
  'Breakfast': breakfastGif
};


const FacilitiesIconsContainer = ({ facilities = [] }) => {
  console.log(facilities);

  return (
    <div className="facilities-container">
    {facilities.map((facility, index) => (
      <div key={index} className="facility-card">
        <img src={facilityIcons[facility] || wifiGif} alt={facility} className="facility-icon" />
        <span className="facility-title">{facility}</span>
      </div>
    ))}
  </div>
  );
};

export default FacilitiesIconsContainer;

import React, { useState } from "react";
import "../../css/RoomInformation/RoomInformation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faRestroom, faCircleChevronDown, faCircleChevronUp,faBed as faBedAlt } from "@fortawesome/free-solid-svg-icons";
import FacilitiesIconsContainer from "../Facilities/facilitiesiconsContainer";
import ColumnList from "../Facilities/facilitiesList";
import HouseRules from "../common/HomeRules";
import SiteCard from "../Sitecard/siteCard";
import CustomCarousel from "./new";
import icon11 from "../../assets/icons/private.jpg"
import icon22 from "../../assets/icons/shared.jpg"
import singleBedIcon from "../../assets/Facilities of BPH/ssbed.png"
import doubleBedIcon from "../../assets/Facilities of BPH/bed.png"
import BunkBedIcon from "../../assets/Facilities of BPH/bunk-bed.png";
import KingBedIcon from "../../assets/Facilities of BPH/kingbed.png";
import LargeDouble from "../../assets/Facilities of BPH/largedouble.png";
import SofaBed from "../../assets/Facilities of BPH/sofa.png";

const bedIconMap = {
  "SINGLE BED": singleBedIcon,
  "DOUBLE BED": doubleBedIcon,
  "BUNK BED": BunkBedIcon,
  "SUPERIOR KING BED": KingBedIcon,
  "LARGE DOUBLE BED": LargeDouble,
  "SOFA BED": SofaBed,
};
const capitalize = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const RoomInformation = ({ room, bookingUrl, selectedRoomId, bathroomType, roomPrice ,bookLink,count_guest, vat, icon}) => {
  const [showList, setShowList] = useState(false);
  const [showTab, setShowTab] = useState("info");

  const handleToggleList = () => setShowList(!showList);
  const handleTabChange = (tab) => setShowTab(tab);


  const bathroomIcon = bathroomType === "Shared" ? icon22 : icon11
  const parseBedInfo = (title) => {
    const bedInfo = [];
    const bedDescriptions = title.split("+");

    bedDescriptions.forEach((desc) => {
      const cleanDesc = desc.trim();
      const match = cleanDesc.match(
        /(\d+) x (SINGLE BED|DOUBLE BED|BUNK BED|LARGE DOUBLE BED|SOFA BED|SUPERIOR KING BED)/
      );
      if (match) {
        const count = parseInt(match[1], 10);
        const bedType = match[2];
        const icon = bedIconMap[bedType];

        if (icon) {
          bedInfo.push({ icon, count, description: capitalize(bedType) });
        }
      }
    });

    return bedInfo;
  };

  const bedInfos = parseBedInfo(room.cardTitle);
  
  return (
    <div className="">
     
      <div className="tabs">
        <button 
          className={`tab-button ${showTab === 'info' ? 'active' : ''}`}
          onClick={() => handleTabChange('info')}
        >
          Room Information
        </button>
        <button 
          className={`tab-button ${showTab === 'policies' ? 'active' : ''}`}
          onClick={() => handleTabChange('policies')}
        >
          Policies
        </button>
      </div>

      {showTab === 'info' && (
        <div>
          <div className="carousel-card">
          <div className="img-care">
            <CustomCarousel roomImages={room.images} />
          </div>

          <div className="site-card-room">
           
           <SiteCard selectedRoomId={selectedRoomId} roomPrice={parseFloat(roomPrice.replace('£', ''))} bookLink={bookLink} vat={room.Vat} count_guest={count_guest} />
          </div>
          </div>

          
          <div className="room-info-text-content">
            <h2 className="RoomInformation-subHeadings">{room.title}</h2>
            <p className="RoomInformation-textP">
            {room.disc}
            </p>
            <div className="RoomInformation-main-container">
            <div className="RoomInformation-icon-container">
                {bedInfos.map((bed, index) => (
                  <div key={index} className="RoomInformation-bed-item">
                    <img src={bed.icon} alt="Bed Icon" style={{ width: "24px", marginRight: "10px" }} />
                    <span className="RoomInformation-iconText">
                    {bed.count} x {bed.description}
                    </span>
                  </div>
                ))}
              </div>

              <div className="RoomInformation-icon-container">
              <div className="bathroom-type">
              <img src={bathroomIcon} alt={`${bathroomType} bathroom`} style={{ width: "20px", marginRight: "10px" }} />
               <span>{bathroomType} Bathroom</span>
               </div>             
              </div>
            </div>
            <div>
              <h2 className="RoomInformation-headings">{showList ? "Facilities" : "Amenities"}</h2>
              <div className="fs-cont">
              <FacilitiesIconsContainer facilities={room.facilities || []} />
              </div>
              {!showList && (
                <div  >
                  <div className="facilitiesshowbutton2"> 
                    <p className='facilitiesshowbutton1' >See all Facilities at Bridge Park Hotel</p>
                    <button className='facilitiesshowbutton1' onClick={handleToggleList}>
                  <FontAwesomeIcon icon={faCircleChevronDown} size='3x' />
                </button></div>
               
                </div>
              )}
              {showList && (
                <div>
                   <div className="facilitiesshowbutton2"> 
                   <p className='facilitiesshowbutton1' >Show Less</p>
                <button className='facilitieshidebutton1' onClick={handleToggleList}>
                  <FontAwesomeIcon icon={faCircleChevronUp} size='3x' />
                </button></div>
                </div>
              )}
              {showList && (
                <div className="columnlist"><ColumnList /></div>
              )}
            </div>
          </div>
        </div>
      )}

      {showTab === 'policies' && (
        <div>
          <HouseRules />
        </div>
      )}
    </div>
  );
};

export default RoomInformation;

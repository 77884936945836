import React from "react";
import { Button, Input, Space } from "antd";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

function NumberPicker({ placeholder, value, onChange, min = 0 }) {
  const increment = () => onChange(value + 1);
  const decrement = () => {
    if (value > min) onChange(value - 1);
  };

  // Function to determine button size based on screen width
  const getButtonSize = () => {
    if (window.innerWidth < 576) {
      // Small screens
      return { width: '10px', height: '', fontSize: '6px' };
    } else if (window.innerWidth < 768) {
      // Medium screens
      return { width: '12px', height: '', fontSize: '8px' };
    } else {
      // Large screens
      return { width: '14px', height: '', fontSize: '10px' };
    }
  };

  const buttonStyle = getButtonSize();

  return (
    <Space>
      <Button 
        shape="circle" 
        onClick={decrement} 
        style={{ 
          zIndex:'10',
          border: "none", 
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          ...buttonStyle,
          padding: '0',
        }}
      >
        <MinusOutlined 
          style={{ 
            color: '#669399', 
            fontWeight: 'bold', 
            fontSize: buttonStyle.fontSize 
          }} 
        />
      </Button>
      <Input 
        value={value} 
        readOnly 
        style={{ 
          width: '60px', 
          textAlign: 'center', 
          border: "none", 
          fontSize: '25px', 
          fontWeight: 'bold' 
        }} 
        placeholder={placeholder} 
      />
      <Button 
        shape="circle" 
        onClick={increment} 
        style={{ 
          border: "none", 
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          ...buttonStyle,
          padding: '0',
        }}
      >
        <PlusOutlined 
          style={{ 
            color: '#669399', 
            fontWeight: 'bold', 
            fontSize: buttonStyle.fontSize 
          }} 
        />
      </Button>
    </Space>
  );
}

export default NumberPicker;

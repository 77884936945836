import React, { useState, useEffect } from "react";
import Images from "../common/Images";
import { Row, Col, Modal } from "antd";
import Card from "../common/Card";
import "../../css/BasicRoom.css";
import { useNavigate } from "react-router-dom";
import RoomInformation from "../home/RoomInformation";
import personIcon2 from "../../assets/icons/two.png";
import bedIcon2 from "../../assets/icons/rest.png";
import bedIcon1 from "../../assets/icons/bed.png";
import personIcon1 from "../../assets/icons/people.png";
import personIcon3 from "../../assets/icons/Three.png";
import UrlLib from "../common/UrlLib";
import HotelRoomCardBasicRoom from "../Room/HotelRoomCardBasicRoom";
import HouseRules from "../common/HomeRules";
import HomeCard from "../Sitecard/homeCard";

const BasicRoom = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [bookingUrl, setBookingUrl] = useState('');
  const [selectedRoomId, setSelectedRoomId] = useState(0);
  const [bathroomType, setBathroomType] = useState('');
  const [roomPrice, setRoomPrice] = useState('');
  const [bookLink, setBookLink] = useState('');
  const [count_guest, setCount_guest] = useState('');
  const navigate = useNavigate();
  const [facilities, setFacilities] = useState([]);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  const handleViewMoreClick = (Basicroom, url , id) => {
    setSelectedRoom(Basicroom);
    setBookingUrl(url);
    setIsModalVisible(true);
    setSelectedRoomId(id);
    setRoomPrice(Basicroom.roomPrice);
    setBookLink(Basicroom.bookLink);
    setCount_guest(Basicroom.count_guest);
    setFacilities(Basicroom.facilities || []);
    if (Basicroom.title.includes('SHARED')) {
      setBathroomType('SHARED');
    } else {
      setBathroomType('PRIVATE');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const getUrlById = (id) => {
    const urlObject = UrlLib.find((url) => url.id === id);
    return urlObject ? urlObject.url : "#";
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const BasicroomData = [
    {
      id: 28, 
      title: "SINGLE ROOM WITH SHARED BATHROOM",
      image: Images.room1[1],
      icon1:personIcon1,
      icon2:bedIcon1,
      cardTitle: "1 x SINGLE BEDS",
      imageUrl: getUrlById(2),
      BroomPrice: "£50",
      vat:"10",
      NoOfGuests: "01",
      images: Images.room1,
      BookingLink:"31929",
      facilities: ["Free WiFi","Non-smoking rooms", "Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],
      Disc:"Contemporary room featuring a single bed and shared bathroom facilities.Located less than a 10-minute travel to Wembley Stadium, it provides easy access to various transport links around London. Includes tea and coffee making facilities, TV with free-view, and free WiFi.",
    },
    {
      id: 29,
      title: "DOUBLE ROOM WITH SHARED BATHROOM",
      image: Images.room2[1],
      icon1:personIcon2,
      icon2:bedIcon2,
      cardTitle: "1 x DOUBLE BED",
      imageUrl: getUrlById(5),
      BroomPrice: "£63",
      vat:"12.6",
      NoOfGuests: "02",
      images: Images.room2,
      BookingLink:"31921",
      Disc:"Standard double room featuring a double bed and shared bathroom facilities. Conveniently located less than a 10-minute travel to Wembley Stadium, this room includes tea and coffee making facilities, TV with free-view, and free WiFi.",
      facilities: ["Free WiFi","Non-smoking rooms", "Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],

    },
    {
      id: 34,
      image:  Images.room3[2],
      title: "TWIN ROOM WITH SHARED BATHROOM",
      icon1:personIcon2,
      icon2:bedIcon2,
      cardTitle: "2 x SINGLE BEDS",
      imageUrl: getUrlById(11),
      BroomPrice: "£68",
      vat:"13.6",
      NoOfGuests: "02",
      images: Images.room3,
      BookingLink:"31934",
      Disc:"Standard twin room with two single beds and shared bathroom facilities. Positioned less than a 10-minute travel to Wembley Stadium, this room provides easy access to various transport links around London. Includes tea and coffee making facilities, TV with free-view, and free WiFi.",
      facilities: ["Free WiFi","Non-smoking rooms", "Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],

    },
    {
      id: 31,
      image: Images.room4[5],
      title: "TRIPLE ROOM WITH SHARED BATHROOM",
      icon1:personIcon3,
      icon2:bedIcon2,
      cardTitle: "3 x SINGLE BEDS",
      imageUrl: getUrlById(9),
      BroomPrice: "£90",
      vat:"18",
      NoOfGuests: "03",
      images: Images.room4,
      BookingLink:"31931",
      Disc:"Spacious room featuring three single beds and shared bathroom facilities. Conveniently located, this room includes tea and coffee making facilities, TV with free-view, and free WiFi. Table and chairs are also available.",
      facilities: ["Free WiFi","Non-smoking rooms", "Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],

    },
  ];

  return (
    <div >
      <div className="BasicRoom-cardContainor">
      {isMobile ? <div className="location-sitecard"><HomeCard /></div> :  <div className='basicroom-card'><Card /></div>}
      </div>
      <div className="BasicRoom-containor">
        <div className="">

          <div className="BasicRoom-TextWrapper">
          <div className="about-sub-content">
                                <h3 style={{textAlign:'center'}}>Lowest Price Guaranteed</h3>
                                <p style={{textAlign:'center'}}>Book here on for peace of mind that you're getting the best possible deal. Thanks to our Lowest Price Guarantee you can rest assured that you'll pay the cheapest rate possible. Book to secure your savings today!</p>
                    </div>
            {/* <h1 className="BasicRoom-aboutH1">BASIC ROOMS</h1> */}
            <div className='location-title'>BASIC ROOMS</div>
          </div>
        </div>
      </div>
      <div className="BsicRoomMainDiv">
        <Row gutter={[16, 16]} className="BasicRoom-row">
          {BasicroomData.map((Basicroom) => (
            <Col key={Basicroom.id} className="BasicRoom-col">
              <HotelRoomCardBasicRoom
                imageSource={Basicroom.image}
                title={Basicroom.title}
                icon1={Basicroom.icon1}
                icon2={Basicroom.icon2}
                description="Spacious room with a breathtaking view"
                guests={2}
                status="Check"
                NoOfGuests={Basicroom.NoOfGuests} 
                price={Basicroom.BroomPrice}
                buttonText="Book Now"
                cardtitle={Basicroom.cardTitle}
                onViewMoreClick={() => handleViewMoreClick({
                  title: Basicroom.title,
                  imageSource: Basicroom.image,
                  cardTitle:Basicroom.cardTitle,
                  id: Basicroom.id,
                  disc: Basicroom.Disc,
                  Vat: Basicroom.vat,
                  roomPrice: Basicroom.BroomPrice,
                  bookLink:Basicroom.BookingLink,
                  count_guest:Basicroom.NoOfGuests,
                  images: Basicroom.images,
                  facilities: Basicroom.facilities
                }, Basicroom.imageUrl,Basicroom.id)}
                link={Basicroom.imageUrl}
              />
            </Col>
          ))}
        </Row>
      </div>
     <div style={{widows:'100%'}}> <HouseRules /></div>
      
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="70%"
      >
        <RoomInformation 
        room={selectedRoom} 
        bookingUrl={bookingUrl} 
        selectedRoomId={selectedRoomId}
        bathroomType={bathroomType}
        roomPrice={roomPrice}
        bookLink={bookLink}
        count_guest={count_guest}
        facilities={facilities}
        />
      </Modal>
    </div>
  );
};

export default BasicRoom;
// images.js
const Images = {
    room1: [
        require('../../assets/BPH_ROOMS/Single Room With Shared Bathroom ( 1 single bed)/1-SS.jpg'),
        require('../../assets/BPH_ROOMS/Single Room With Shared Bathroom ( 1 single bed)/2-SS.jpg'),
        require('../../assets/BPH_ROOMS/Single Room With Shared Bathroom ( 1 single bed)/3-SS.jpg'),
        require('../../assets/BPH_ROOMS/Single Room With Shared Bathroom ( 1 single bed)/4-SS.jpg'),
        require('../../assets/BPH_ROOMS/Single Room With Shared Bathroom ( 1 single bed)/5-SS.jpg'),
        require('../../assets/BPH_ROOMS/Single Room With Shared Bathroom ( 1 single bed)/6-SS.jpg'),
        require('../../assets/BPH_ROOMS/Single Room With Shared Bathroom ( 1 single bed)/7-SS.webp'),
        require('../../assets/BPH_ROOMS/Single Room With Shared Bathroom ( 1 single bed)/8-SS.jpg'),
        require('../../assets/BPH_ROOMS/Single Room With Shared Bathroom ( 1 single bed)/9-SS.jpg'),
        require('../../assets/BPH_ROOMS/Single Room With Shared Bathroom ( 1 single bed)/10-SS.jpg'),
        require('../../assets/BPH_ROOMS/Single Room With Shared Bathroom ( 1 single bed)/11-SS.jpg'),
        require('../../assets/BPH_ROOMS/Single Room With Shared Bathroom ( 1 single bed)/12-SS.jpg'),
        // Add more images as needed
    ],
    room2: [
        require('../../assets/BPH_ROOMS/Double Room With Shared Bathroom (1 double bed )/1-DS.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Shared Bathroom (1 double bed )/2-DS.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Shared Bathroom (1 double bed )/4-DS.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Shared Bathroom (1 double bed )/5-DS.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Shared Bathroom (1 double bed )/6-DS.webp'),
        require('../../assets/BPH_ROOMS/Double Room With Shared Bathroom (1 double bed )/7-DS.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Shared Bathroom (1 double bed )/8-DS.jpg'),
    ],
    room3: [
        require('../../assets/BPH_ROOMS/Twin Room With Shared Bathroom ( 2 single beds)/1-TWS.jpg'),
        require('../../assets/BPH_ROOMS/Twin Room With Shared Bathroom ( 2 single beds)/2-TWS.jpg'),
        require('../../assets/BPH_ROOMS/Twin Room With Shared Bathroom ( 2 single beds)/3-TWS.jpg'),
        require('../../assets/BPH_ROOMS/Twin Room With Shared Bathroom ( 2 single beds)/4-TWS.jpg'),
        require('../../assets/BPH_ROOMS/Twin Room With Shared Bathroom ( 2 single beds)/5-TWS.jpg'),
        require('../../assets/BPH_ROOMS/Twin Room With Shared Bathroom ( 2 single beds)/6-TWS.jpg'),
        require('../../assets/BPH_ROOMS/Twin Room With Shared Bathroom ( 2 single beds)/7-TWS.webp'),
        require('../../assets/BPH_ROOMS/Twin Room With Shared Bathroom ( 2 single beds)/9-TWS.webp'),
        require('../../assets/BPH_ROOMS/Twin Room With Shared Bathroom ( 2 single beds)/10-TWS.jpg'),
        require('../../assets/BPH_ROOMS/Twin Room With Shared Bathroom ( 2 single beds)/11-TWS.jpg'),
        require('../../assets/BPH_ROOMS/Twin Room With Shared Bathroom ( 2 single beds)/12-TWS.jpg'),
      

    ],
    room4: [
        require('../../assets/BPH_ROOMS/Triple Room With Shared Bathroom (3 single beds)/1 -TRS.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Shared Bathroom (3 single beds)/2 -TRS.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Shared Bathroom (3 single beds)/3 -TRS.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Shared Bathroom (3 single beds)/4 -TRS.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Shared Bathroom (3 single beds)/5 -TRS.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Shared Bathroom (3 single beds)/6 -TRS.jpg'),
    ],
    room5:[
        require('../../assets/BPH_ROOMS/Double Room With Private Bathroom (1 double bed)/1-DP.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Private Bathroom (1 double bed)/2-DP.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Private Bathroom (1 double bed)/3-DP.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Private Bathroom (1 double bed)/4-DP.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Private Bathroom (1 double bed)/5-DP.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Private Bathroom (1 double bed)/6-DP.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Private Bathroom (1 double bed)/7-DP.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Private Bathroom (1 double bed)/8-DP.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Private Bathroom (1 double bed)/9-DP.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Private Bathroom (1 double bed)/10-DP.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Private Bathroom (1 double bed)/11-DP.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Private Bathroom (1 double bed)/12-DP.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Private Bathroom (1 double bed)/14-DP.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Private Bathroom (1 double bed)/15-DP.jpg'),
        require('../../assets/BPH_ROOMS/Double Room With Private Bathroom (1 double bed)/16-DP.jpg'),
    ],
    room6:[
        require('../../assets/BPH_ROOMS/Twin Room With Private Bathroom ( 2 Single beds)/1 - TWP.jpg'),
        require('../../assets/BPH_ROOMS/Twin Room With Private Bathroom ( 2 Single beds)/2 - TWP.jpg'),
        require('../../assets/BPH_ROOMS/Twin Room With Private Bathroom ( 2 Single beds)/3 - TWP.jpg'),
        require('../../assets/BPH_ROOMS/Twin Room With Private Bathroom ( 2 Single beds)/4 - TWP.jpg'),
        require('../../assets/BPH_ROOMS/Twin Room With Private Bathroom ( 2 Single beds)/5 - TWP.jpg'),
        require('../../assets/BPH_ROOMS/Twin Room With Private Bathroom ( 2 Single beds)/6 - TWP.webp'),
        require('../../assets/BPH_ROOMS/Twin Room With Private Bathroom ( 2 Single beds)/7 - TWP.webp'),
        require('../../assets/BPH_ROOMS/Twin Room With Private Bathroom ( 2 Single beds)/8 - TWP.webp'),
        require('../../assets/BPH_ROOMS/Twin Room With Private Bathroom ( 2 Single beds)/9 - TWP.jpg'),
    ],
    room7:[
        require('../../assets/BPH_ROOMS/Triple Room With Private Bathroom (3 single beds)/1 - TR3P.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Private Bathroom (3 single beds)/2 - TR3P.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Private Bathroom (3 single beds)/3 - TR3P.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Private Bathroom (3 single beds)/4 - TR3P.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Private Bathroom (3 single beds)/5 - TR3P.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Private Bathroom (3 single beds)/6 - TR3P.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Private Bathroom (3 single beds)/7 - TR3P.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Private Bathroom (3 single beds)/10 - TR3P.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Private Bathroom (3 single beds)/11 - TR3P.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Private Bathroom (3 single beds)/12 - TR3P.jpg'),
    ],   
     room8:[
        require('../../assets/BPH_ROOMS/Triple Room With Private Bathroom (1  double bed +1 single bed)/1 - TRP.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Private Bathroom (1  double bed +1 single bed)/2 - TRP.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Private Bathroom (1  double bed +1 single bed)/3 - TRP.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Private Bathroom (1  double bed +1 single bed)/5 - TRP.jpg'),
        require('../../assets/BPH_ROOMS/Triple Room With Private Bathroom (1  double bed +1 single bed)/6 - TRP.webp'),
        require('../../assets/BPH_ROOMS/Triple Room With Private Bathroom (1  double bed +1 single bed)/7 - TRP.webp'),
    ],  
     room9:[
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom ( 1 double bed + 2 single beds)/1 - QP.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom ( 1 double bed + 2 single beds)/2 - QP.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom ( 1 double bed + 2 single beds)/3 - QP.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom ( 1 double bed + 2 single beds)/4 - QP.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom ( 1 double bed + 2 single beds)/5 - QP.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom ( 1 double bed + 2 single beds)/6 - QP.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom ( 1 double bed + 2 single beds)/7 - QP.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom ( 1 double bed + 2 single beds)/8 - QP.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom ( 1 double bed + 2 single beds)/9 - QP.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom ( 1 double bed + 2 single beds)/11 - QP.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom ( 1 double bed + 2 single beds)/12 - QP.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom ( 1 double bed + 2 single beds)/13 - QP.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom ( 1 double bed + 2 single beds)/14 - QP.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom ( 1 double bed + 2 single beds)/15 - QP.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom ( 1 double bed + 2 single beds)/16 - QP.jpg'),
    ],     
    room10:[
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom  ( 1 double bed + 1 bunk bed )/1 - QPB.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom  ( 1 double bed + 1 bunk bed )/2 - QPB.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom  ( 1 double bed + 1 bunk bed )/3 - QPB.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom  ( 1 double bed + 1 bunk bed )/4 - QPB.jpg'),
        require('../../assets/BPH_ROOMS/Quadruple Room With Private Bathroom  ( 1 double bed + 1 bunk bed )/5 - QPB.jpg'),
        
    ],
    room11:[
        require('../../assets/BPH_ROOMS/Executive King Room With Private Bathroom ( 1 Large double bed  + 1 sofa bed )/1 - EKP.jpg'),
        require('../../assets/BPH_ROOMS/Executive King Room With Private Bathroom ( 1 Large double bed  + 1 sofa bed )/2 - EKP.jpg'),
        require('../../assets/BPH_ROOMS/Executive King Room With Private Bathroom ( 1 Large double bed  + 1 sofa bed )/3 - EKP.jpg'),
        require('../../assets/BPH_ROOMS/Executive King Room With Private Bathroom ( 1 Large double bed  + 1 sofa bed )/4 - EKP.jpg'),
        require('../../assets/BPH_ROOMS/Executive King Room With Private Bathroom ( 1 Large double bed  + 1 sofa bed )/5 - EKP.jpg'),
        require('../../assets/BPH_ROOMS/Executive King Room With Private Bathroom ( 1 Large double bed  + 1 sofa bed )/6 - EKP.jpg'),
        require('../../assets/BPH_ROOMS/Executive King Room With Private Bathroom ( 1 Large double bed  + 1 sofa bed )/7 - EKP.jpg'),
        require('../../assets/BPH_ROOMS/Executive King Room With Private Bathroom ( 1 Large double bed  + 1 sofa bed )/8 - EKP.jpg'),
    ],
    room12:[
        require('../../assets/BPH_ROOMS/Chambre of Twilight Room With Private Bathroom (1 superior king bed )/1 - CTP.webp'),
        require('../../assets/BPH_ROOMS/Chambre of Twilight Room With Private Bathroom (1 superior king bed )/2 - CTP.webp'),
        require('../../assets/BPH_ROOMS/Chambre of Twilight Room With Private Bathroom (1 superior king bed )/3 - CTP.webp'),
        require('../../assets/BPH_ROOMS/Chambre of Twilight Room With Private Bathroom (1 superior king bed )/4 - CTP.webp'),
        require('../../assets/BPH_ROOMS/Chambre of Twilight Room With Private Bathroom (1 superior king bed )/5 - CTP.webp')
    ],
    image_360:[
        require('../../assets/BPH_ROOMS/360/1new - 360.jpg'),
        require('../../assets/BPH_ROOMS/360/4new-360.jpg'),
        require('../../assets/BPH_ROOMS/360/1.jpg'),
        require('../../assets/BPH_ROOMS/360/2.jpg'),
        require('../../assets/BPH_ROOMS/360/3.jpg'),
        require('../../assets/BPH_ROOMS/360/4.jpg'),
        require('../../assets/BPH_ROOMS/360/5.jpg'),
        require('../../assets/BPH_ROOMS/360/6.jpg'),


    ],
    image_gallery:[
        require('../../assets/Image-slider-componet/image1.jpg'),
        require('../../assets/Image-slider-componet/image2.jpg'),
        require('../../assets/Image-slider-componet/image3.jpg'),
        require('../../assets/Image-slider-componet/image4.jpg'),
        require('../../assets/Image-slider-componet/image5.jpg'),
        require('../../assets/Image-slider-componet/image6.jpg'),
        require('../../assets/Image-slider-componet/image7.jpg'),
    ]

    
    
    // Add more rooms similarly
};

export default Images;

import React from "react";
import "./RoomCard.css";
import { Row, Col } from "antd";
import SingleBedIcon from "../../assets/icons/single_bed.png"; 
import DoubleBedIcon from "../../assets/icons/bed_double.png"; 
import KingBedIcon from "../../assets/icons/king_bed.png"; 
import Sofabed from "../../assets/icons/Sofa.png"
const bedIconMap = {
  "Single Bed": SingleBedIcon,
  "Double Bed": DoubleBedIcon,
  "Large Double Bed": KingBedIcon,
  "Sofa Bed" : Sofabed
};

const RoomCard = ({
  imageSource,
  price,
  cardtitle,
  onViewMoreClick,
 

}) => {
  
  return (
    
    <div className="hotelRoomCardContainer-R1">
      <div className="hotelRoomCardContainer1-R1">
   
        <img src={imageSource} className="hotelRoomCardImage-R1" alt="" />
        <h1 className="hotelRoomCardTopBar-R1">{cardtitle}</h1>
 
     
      </div>
      <div className="hotelRoomCardDetails-R1">
      <Row className="hotelRoomCard-MainRow-R1">
      <Col span={12}>
            <p className="hotelRoomCardPriceText-R1">Starts with  {price}</p>

          </Col>
          <Col span={12} >
          <div className="hotelRoomViewMoreLink-R1" onClick={onViewMoreClick}>
            View More
          </div>
          </Col>
        </Row>
       
      </div>
    </div>
    // </div>
  );
};

export default RoomCard;

import React from 'react';
import { Carousel } from 'antd';
import '../../css/RoomInformation/new.css';

const CustomCarousel = ({ roomImages }) => {
  if (!roomImages || roomImages.length === 0) {
    return <div>No images available</div>;
  }

  return (
    <div className='new-main'>
      <Carousel 
        autoplay 
        autoplaySpeed={2000} 
        effect="fade" 
        className="new-custom-carousel" 
        dots={true} 
        arrows={true}
      >
        {roomImages.map((image, index) => (
          <div key={index} className="carousel-slide">
            <img src={image} className="carousel-image" alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CustomCarousel;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Collapse } from "antd";
import { CaretRightOutlined, CaretDownOutlined } from "@ant-design/icons";
import "./Faq.css";
import Card from "../common/Card";
import SiteCard from "../Sitecard/siteCard";
import FaqComponent from "./FaqComponent";
import HomeCard from "../Sitecard/homeCard";
import { Helmet } from "react-helmet";

const { Panel } = Collapse;

const FAQ = () => {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 820px)").matches
  );
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 820px)").matches);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="faq">
      <Helmet>
        <title>Frequently Asked Questions - Bridge Park Hotel</title>
        <meta name="description" content="Find answers to common questions about Bridge Park Hotel. Our FAQ page covers everything you need to know about our services, policies, and amenities." />
        <meta name="keywords" content="Bridge Park Hotel FAQ, hotel frequently asked questions, hotel policies, hotel services, London hotel FAQs, Bridge Park Hotel information" />
        <meta name="author" content="Bridge Park Hotel" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Frequently Asked Questions - Bridge Park Hotel" />
        <meta property="og:description" content="Get answers to frequently asked questions about Bridge Park Hotel. Learn more about our services, policies, and what to expect during your stay in London." />
        <meta property="og:image" content="URL_to_image_related_to_FAQ_or_hotel.jpg" /> {/* Replace with actual URL to an image related to FAQs or the hotel */}
        <meta property="og:url" content="https://www.bridgeparkhotel.co.uk/faq" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.bridgeparkhotel.co.uk/faq" />
      </Helmet>
      <div className="faq-main">
        {isMobile ? (
         <div className="location-sitecard"><HomeCard /></div>
        ) : (
          <div className="faq-card">
            <Card />
          </div>
        )}
      </div>
      <div className="faq-title">FAQ</div>
      <div className="faq-sub"></div>
      <FaqComponent/>
    </div>
  );
};

export default FAQ;

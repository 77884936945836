export const reviewData = [
  {
    reviewId: 1,
    name: "Francesco",
    country: "Australia",
    roomId: 102,
    nights: 4,
    travelerType: "Couple",
    reviewDate: "2024-06-25",
    reviewerChoice: false,
    title:
      "Clean, quiet and comfortable property with nice Staff. Good location to public transport.",
    comment:
      "The property was clean, quiet and comfortable. Cooked breakfast was very good. Most importantly Staff were welcoming and coureous. Special thanks to Housekeeping man who attended in a very responsive way to a few minor issues.",
    overallStar: 14,
    helpful: 3,
    notHelpful: 1,
  },
  {
    reviewId: 2,
    name: "Nick",
    country: "United Kingdom",
    roomId: 203,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2024-07-09",
    reviewerChoice: false,
    title: "Good",
    comment: "Clean hotel with friendly service. Good parking easy access",
    overallStar: 16,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 3,
    name: "David",
    country: "United Kingdom",
    roomId: 305,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2024-07-09",
    reviewerChoice: false,
    title: "Good",
    comment:
      "Was there for a concert at Wembley stadium so the location was great. The room was just somewhere to lay our heads overnight. It was satisfactory. It was all satisfactory. Nothing I particularly disliked.",
    overallStar: 14,
    helpful: 1,
    notHelpful: 1,
  },
  {
    reviewId: 4,
    name: "Gill",
    country: "United Kingdom",
    roomId: 402,
    nights: 2,
    travelerType: "Family",
    reviewDate: "2024-07-08",
    reviewerChoice: false,
    title: "Good",
    comment:
      "Good location, with parking. Nice big room for 3 — the main bed was huge and comfortable, and the pull-out was large, and also really comfortable — amazing for a pull-out! (Better than the Premier Inn pull-outs 😊) Good-sized bathroom, good shower. Lovely staff, very friendly and obliging. Breakfast basic, but very generous. A solid 3 stars. Some of the fitments were a bit dilapidated — door lock and tap were loose and needed mending / replacing. The net curtains have seen better days. There was a noise issue, with the outside bar seating right outside the windows (which were also a little old).",
    overallStar: 18,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 5,
    name: "Sebadindin",
    country: "Taiwan",
    roomId: 108,
    nights: 4,
    travelerType: "Solo",
    reviewDate: "2024-07-07",
    reviewerChoice: false,
    title: "Cute hotel",
    comment:
      "It was clean and comfortable, I liked the old-style charm, staff was friendly and the price was very reasonable ! There was nothing I didn't like, I knew what to expect from other people's reviews and actually, my expectations were exceeded.",
    overallStar: 17,
    helpful: 2,
    notHelpful: 1,
  },
  {
    reviewId: 6,
    name: "Sharon",
    country: "United Kingdom",
    roomId: 210,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2024-07-05",
    reviewerChoice: false,
    title: "Good",
    comment:
      "Excellent location for going to Wembley, bus stop just across the road to get there and just up the road for coming back. Staff helpful and pleasant. Breakfast good. Room basic but enough for what you need for an overnight stay. Rooms slightly dated and in need of a little attention.",
    overallStar: 17,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 7,
    name: "Gibson",
    country: "United Kingdom",
    roomId: 307,
    nights: 1,
    travelerType: "Couple",
    reviewDate: "2024-06-30",
    reviewerChoice: true,
    title: "Excellent value for money",
    comment:
      "We stayed for 1 night for a gig at Wembley and were very happy with our stay. The location was excellent. Bus 18 stops outside which takes you to Wembley very close to the stadium. The queues for bus back were mental so we walked it. It is 40 mins but its a straight walk, well lit with convenience shops and a couple of pubs along the way. Harlesdon tube is a short walk away from the hotel and a short trip on bakerloo line gets you to Central London in under 20mins. Excellent value for money in my opinion, you'll be hard pushed to find somewhere in London for the price we paid with free parking, free WiFi and breakfast included. Breakfast was great, staff were friendly and helpful. We will 100% stay here again. It was dated but we knew that from the photos. There is mildew stains in the bathroom but there was no mould as other reviews suggested. Beds were clean, no smell. It's all just a bit old and outdated.",
    overallStar: 17,
    helpful: 3,
    notHelpful: 1,
  },
  {
    reviewId: 8,
    name: "Oberemok",
    country: "United Kingdom",
    roomId: 211,
    nights: 2,
    travelerType: "Family",
    reviewDate: "2024-06-28",
    reviewerChoice: false,
    title: "Good",
    comment:
      "Good location, parking and friendly staff. Small rooms and slightly outdated furniture.",
    overallStar: 16,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 9,
    name: "Steven",
    country: "United Kingdom",
    roomId: 102,
    nights: 2,
    travelerType: "Family",
    reviewDate: "2024-06-14",
    reviewerChoice: false,
    title: "Good",
    comment:
      "Great location for our trip to Wembley comfortable rooms. The hotel is underachieving as they have a very comfortable restaurant customers could relax in and no doubt get served great food but for reasons only known to management remains permanently closed.",
    overallStar: 18,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 10,
    name: "Paul",
    country: "United Kingdom",
    roomId: 208,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2024-06-12",
    reviewerChoice: false,
    title: "Good location to visit Wembley from",
    comment:
      "Room was suitable for an adult and two children. Breakfast was substantial.",
    overallStar: 16,
    helpful: 2,
    notHelpful: 1,
  },
  {
    reviewId: 11,
    name: "Riley-judd",
    country: "United Kingdom",
    roomId: 304,
    nights: 1,
    travelerType: "Couple",
    reviewDate: "2024-06-10",
    reviewerChoice: false,
    title: "Good",
    comment:
      "Staff really friendly. Beds uncomfortable rooms tired looking and very dated.",
    overallStar: 17,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 12,
    name: "Dave",
    country: "United Kingdom",
    roomId: 110,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2024-05-28",
    reviewerChoice: false,
    title:
      "Good location, needs a refurb but decent for price and 1/2 from Wembley",
    comment: "Location and staff. Bit dated.",
    overallStar: 15,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 13,
    name: "Vincent",
    country: "United Kingdom",
    roomId: 209,
    nights: 1,
    travelerType: "Solo",
    reviewDate: "2024-05-14",
    reviewerChoice: false,
    title: "Very good",
    comment:
      "Staff very friendly and helpful and welcoming. It's in need of a refurbish.",
    overallStar: 18,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 14,
    name: "Neli",
    country: "United Kingdom",
    roomId: 306,
    nights: 2,
    travelerType: "Couple",
    reviewDate: "2024-04-25",
    reviewerChoice: true,
    title: "Location Value clean parking included",
    comment: "Location with parking.Plumbing could be abit better",
    overallStar: 15,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 15,
    name: "Laura",
    country: "United Kingdom",
    roomId: 105,
    nights: 2,
    travelerType: "Couple",
    reviewDate: "2024-04-22",
    reviewerChoice: false,
    title: "Good",
    comment:
      "Friendly staff, great location for easy access to tube into London. Lovely room. Much better than the reviews made out. Would stay again",
    overallStar: 15,
    helpful: 2,
    notHelpful: 1,
  },
  {
    reviewId: 16,
    name: "Ali",
    country: "United Kingdom",
    roomId: 212,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2024-04-19",
    reviewerChoice: false,
    title: "Function over form",
    comment:
      "Our room had a balcony. The room was functional, and had a private bathroom. It's not a five star hotel so expectation ls were managed. The added breakfast was good and the staff were friendly. They had a 24hrs staffing, so coming back late as we did, was no problem at all.Was quite small, though functional for a 1 nite stay. Can't really fault it. Could do with a little updating as it fel a little stagnant.",
    overallStar: 14,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 17,
    name: "Lynda",
    country: "United Kingdom",
    roomId: 107,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2024-03-28",
    reviewerChoice: false,
    title:
      "We enjoyed it, wembley by bus ok outwards but may have to walk 1.5 miles back due to traffic issues.",
    comment:
      "Public transport access to wembley, free parking, good breakfast at a reasonable price. Staff were very helpful and friendly and we were able to check in earlier than anticipated.Rooms a bit dated. Hotel decor could do with updated. Room price paid seem vastly different than others had paid going direct. The website could do with some more information on some of their facilities.",
    overallStar: 18,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 18,
    name: "Lee",
    country: "Ireland",
    roomId: 210,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2024-03-25",
    reviewerChoice: false,
    title: "Great price - ok for our 1 night stay close to Wembley",
    comment:
      "For the price we paid we were happy with the hotel. Close enough to Wembley Stadium without breaking the bank for a family of 4.Everything was ok",
    overallStar: 15,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 19,
    name: "Doug",
    country: "Canada",
    roomId: 103,
    nights: 1,
    travelerType: "Solo",
    reviewDate: "2024-01-09",
    reviewerChoice: true,
    title: "Good",
    comment: "Staff were very friendly and kind. 5 stars for the staff.",
    overallStar: 17,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 20,
    name: "Alana",
    country: "United Kingdom",
    roomId: 209,
    nights: 1,
    travelerType: "Solo",
    reviewDate: "2024-05-05",
    reviewerChoice: false,
    title: "Perfect for a short stay for events",
    comment:
      "Clean and safe, lovely staff, very helpful. Brilliant location short walk from tube station and very close to Wembley Arena.Room quite basic but good value for the price",
    overallStar: 16,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 21,
    name: "Raz",
    country: "United Kingdom",
    roomId: 106,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2023-12-30",
    reviewerChoice: true,
    title: "Good",
    comment: "Good breakfast, friendly staff, free car parking in London.",
    overallStar: 15,
    helpful: 2,
    notHelpful: 1,
  },
  {
    reviewId: 22,
    name: "Mandy",
    country: "United Kingdom",
    roomId: 308,
    nights: 1,
    travelerType: "Couple",
    reviewDate: "2023-12-29",
    reviewerChoice: true,
    title: "I would stay again if I was going to wembley",
    comment:
      "Staff friendly, breakfast great quality and worth money, great location to tube for wembley.It’s a bit outdated , needs updating but was fine for 1 night",
    overallStar: 18,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 23,
    name: "Claire",
    country: "United Kingdom",
    roomId: 104,
    nights: 1,
    travelerType: "Group",
    reviewDate: "2023-12-14",
    reviewerChoice: true,
    title: "Good",
    comment: "Clean sheets .Comfortable.No lift but otherwise ok",
    overallStar: 17,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 24,
    name: "Jade",
    country: "United Kingdom",
    roomId: 211,
    nights: 2,
    travelerType: "Family",
    reviewDate: "2023-12-11",
    reviewerChoice: false,
    title: "Comfortable bedroom and friendly staff",
    comment:
      "Nice spacious bedrooms, very friendly staff.The bathroom was very small side. Side lights did not work, toilet seat broken. Maintenance work (drilling in an adjacent room) quite loud early in the morning",
    overallStar: 15,
    helpful: 2,
    notHelpful: 1,
  },
  {
    reviewId: 25,
    name: "Josh",
    country: "United Kingdom",
    roomId: 305,
    nights: 1,
    travelerType: "Solo",
    reviewDate: "2023-12-08",
    reviewerChoice: false,
    title: "Good",
    comment:
      "Cheap price.You have to give the keys to the front desk when going out, i dont like this and would prefer to keep them",
    overallStar: 18,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 26,
    name: "Karen",
    country: "United Kingdom",
    roomId: 204,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2023-12-08",
    reviewerChoice: false,
    title: "Good",
    comment:
      "You can park right at the front of the hotel to take your bags in and then there is a free car park right next to the hotel with security. There is a choice of shower or bath and a fridge in the room. Staff were friendly and helpful and the room was clean.The lock on our room was very difficult to lock we had to ask for help which took up time as we were on the 2nd floor. I couldn't find what the number to ring reception was so had to go downstairs for help. One of the drawers was broken.",
    overallStar: 17,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 27,
    name: "Maryssy",
    country: "United Kingdom",
    roomId: 101,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2023-12-04",
    reviewerChoice: true,
    title: "Close to the tube but need improvement",
    comment:
      "Comfortable bed with clean sheets. Kettle with coffee and tea inside the bedroom. Basin and little table/wardrobe plus a chair in the bedroom. The old gentleman at the reception was very nice to allow car park space with no problem and a very polite person to explain anything you needed. Less than 10 minutes by walk to reach the Harlesden tube. Not insulated bedrooms you can hear people talking loud late at night in the bedroom at the end of the corridor that were 5 bedrooms away from us. Free car park.The room needs to be refreshed the paint is very old and dirty paint in the walls. The old style metal handle to close the door in the bedroom is not new and we took 5 minutes to close it as it's wibbly wobbly and it looks like it's going to be falling out from the wooden door. Doors and carpet need to be changed it's very old. First floor shared bathrooms are not cleaned every hour in fact who did the shower before I used the toilet left hairs and black pube hairs in the shower and that was literally disgusting for me that I needed only to use it to do wee. I never could use the shower as it was not cleaned. The toilet paper was stuck inside the roll plastic dispenser and nobody came to replace/adjust it all day so I ended up using mine tissues. Lights that didn't work in the bedroom: the one close to my bed side, the one on the basin and the one over the mirror.",
    overallStar: 11,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 28,
    name: "Theresa",
    country: "United Kingdom",
    roomId: 208,
    nights: 1,
    travelerType: "Couple",
    reviewDate: "2023-12-04",
    reviewerChoice: false,
    title: "Good",
    comment: "Good value for money",
    overallStar: 15,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 29,
    name: "Matthew",
    country: "United Kingdom",
    roomId: 228,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2023-11-22",
    reviewerChoice: false,
    title: "Good",
    comment: "Location for Wembley was spot on!!Room had a funny smell",
    overallStar: 18,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 30,
    name: "Geoff",
    country: "United Kingdom",
    roomId: 229,
    nights: 1,
    travelerType: "Solo",
    reviewDate: "2023-12-22",
    reviewerChoice: false,
    title:
      "Really good value for money, clean and handy for Wembley and the Number 18 bus stop outside",
    comment:
      "Bedding was exceptionally clean and radiator worked well.Bar closed on the nail at 11",
    overallStar: 18,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 31,
    name: "Sundip",
    country: "United Kingdom",
    roomId: 230,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2023-11-19",
    reviewerChoice: false,
    title: "Good value, staff were nice and friendly, did the job.",
    comment: "Good price.Very dated needs updating",
    overallStar: 17,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 32,
    name: "Sean",
    country: "United Kingdom",
    roomId: 231,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2023-11-16",
    reviewerChoice: false,
    title: "ok base for wembly arena and stadium",
    comment: "easy to book in , breakfast was ok",
    overallStar: 13,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 33,
    name: "Matthew",
    country: "United Kingdom",
    roomId: 232,
    nights: 2,
    travelerType: "Family",
    reviewDate: "2023-11-16",
    reviewerChoice: false,
    title: "Good",
    comment:
      "Friendly staff.Surprisingly comfy double bed.Bedding was thoroughly clean.Some TLC needed in rooms as dated.Some cleaning issues needed attention, coffee stains on doors, some dirt top of bunkbeds.Shower pressure poor.",
    overallStar: 15,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 34,
    name: "Marija",
    country: "Serbia",
    roomId: 233,
    nights: 4,
    travelerType: "Family",
    reviewDate: "2023-11-07",
    reviewerChoice: false,
    title: "It was rather an ok stay",
    comment:
      "Specious room.Coffee/tea in the room.Comfie bed.Clean, good quality sheets and towels.Good wi-fi.Tesco opened till 11pm just next to the hotel.Private bathroom.Good parking if you need one.It was cold, most of the time the heating was off. We got a portable heater on the next day (thanks to Given - hope I got the name right) which had saved us as we had slept fully dressed on the first night.Damp smell in the room.The tap in the bathtub was not working properly, so it was impossible to set up the water at the right temperature.Drafty windows.Pretty far 1.5h by bus to the city centre by 2 buses.",
    overallStar: 16,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 35,
    name: "Khalid",
    country: "Morocco",
    roomId: 234,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2023-11-02",
    reviewerChoice: false,
    title: "Good",
    comment: "Not that bad.Toilet was not clean",
    overallStar: 16,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 36,
    name: "Kirsty",
    country: "United Kingdom",
    roomId: 235,
    nights: 1,
    travelerType: "Solo",
    reviewDate: "2023-11-02",
    reviewerChoice: false,
    title: "Fine for a nights’ stay",
    comment: "Price.Dated interior",
    overallStar: 14,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 37,
    name: "Larcder124",
    country: "United Kingdom",
    roomId: 236,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2023-10-19",
    reviewerChoice: false,
    title: "Good",
    comment: "Good.Nothing",
    overallStar: 18,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 38,
    name: "Marc",
    country: "Germany",
    roomId: 237,
    nights: 2,
    travelerType: "Group",
    reviewDate: "2023-10-12",
    reviewerChoice: false,
    title:
      "Nice budget location in North London for people without big expectations.",
    comment:
      "One of the few accommodations in this area. Very large and spacious rooms, 24/7 reception, lots of parking and nice building.The rooms in the basement are very gloomy. The smell of strong cleaning products is disturbing. I had booked and my friends had to wait over an hour for me to check in. The receptionist would not hand over the keys without payment even though the reservation was final for all of us with no cancellation option. Not a good service",
    overallStar: 15,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 39,
    name: "Justyna",
    country: "United Kingdom",
    roomId: 238,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2023-09-30",
    reviewerChoice: false,
    title: "Good",
    comment:
      "Perfect location and close to local amenities.Room was fairly dark as it was in the basement",
    overallStar: 18,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 40,
    name: "Christine",
    country: "United Kingdom",
    roomId: 239,
    nights: 16,
    travelerType: "Solo",
    reviewDate: "2023-09-28",
    reviewerChoice: false,
    title: "Basic value find with friendly staff",
    comment:
      "The staff were friendly and went out of their way to help. I found the room quite cold so they found an extra portable heater I could use. For a cheap stay it served my purposes, I had the smallest room which was a bit run down, but caught a peak into the executive suites when the cleaning staff aired them out and the larger rooms looked lovely.The room and bathroom had an unpleasant odor, and there was no air conditioning on the hotter days. The area itself felt a bit rough, and there was a lot of noise, day and night, from people yelling and car stereos being set to max.",
    overallStar: 13,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 41,
    name: "Jessica",
    country: "Canada",
    roomId: 240,
    nights: 2,
    travelerType: "Family",
    reviewDate: "2024-07-21",
    reviewerChoice: false,
    title:
      "What you’d expect for the price, location and based on other reviews.",
    comment:
      "The staff were wonderful! Location was convenient for our needs. Tesco Express 1 minute up the street. Harlesden tube stop 8 minute walk away. Price worked in our budget. Lots of other Swifties!!As many have mentioned, it is very dated and not exceptionally clean. Our room was very hot and smelled overwhelmingly of cigarette smoke.Having traveled plenty over the years and stayed in many a hostel, the shared bathroom wasn’t something I hadn’t experienced before but it’s not for everyone. My 13 year old daughter didn’t love it, but it wasn’t the end of the world!",
    overallStar: 12,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 42,
    name: "Lee",
    country: "United Kingdom",
    roomId: 241,
    nights: 1,
    travelerType: "Solo",
    reviewDate: "2024-07-12",
    reviewerChoice: false,
    title: "Over priced coffin",
    comment:
      "Breakfast was fine.Room was like a small coffin. Bed un comfy and too small, open socket in the room, asked for pillow, ignored, not worth the price",
    overallStar: 13,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 43,
    name: "Amanda",
    country: "United Kingdom",
    roomId: 242,
    nights: 1,
    travelerType: "Couple",
    reviewDate: "2024-07-09",
    reviewerChoice: false,
    title: "Lovely staff needs tlc",
    comment:
      "Great location needs updating but.Ok for the price.Needs a few jobs Doing. But in for the short stay",
    overallStar: 16,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 44,
    name: "Sean",
    country: "United Kingdom",
    roomId: 243,
    nights: 2,
    travelerType: "Couple",
    reviewDate: "2024-07-04",
    reviewerChoice: false,
    title: "Great location, cheap hotel you pay for what you get👍",
    comment: "Free parking and location to main motorway",
    overallStar: 18,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 45,
    name: "Jacqueline",
    country: "United Kingdom",
    roomId: 244,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2024-07-03",
    reviewerChoice: false,
    title: "Passable",
    comment: "Nothing much apart from the price . Quite so",
    overallStar: 15,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 46,
    name: "Jamie",
    country: "United Kingdom",
    roomId: 245,
    nights: 2,
    travelerType: "Group",
    reviewDate: "2024-04-03",
    reviewerChoice: false,
    title: "About what I expected, shower could be better",
    comment:
      "It was cheap: 80 per night for a twin room 1 stop on the tube from Wembley. Apart from the shower everything was fine, sheets were clean, the room was tidy. It wasn't glamourous but it was what you'd expect for that price in London.The shower was atrocious. The water dribbled out, no pressure at all, we were on the top floor though so that's probably why.",
    overallStar: 16,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 47,
    name: "Linda",
    country: "United Kingdom",
    roomId: 246,
    nights: 2,
    travelerType: "Group",
    reviewDate: "2024-07-01",
    reviewerChoice: false,
    title:
      "Room dated . Balcony added bonus .Shared bathroom very clean. 45 minute walk to wembley stadium .",
    comment:
      "Clean bedding and towels.Added bonus of a balcony .Staff were lovely .Good breakfast.Rooms very dated and slightly grubby",
    overallStar: 14,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 48,
    name: "Emily",
    country: "United Kingdom",
    roomId: 247,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2024-06-26",
    reviewerChoice: false,
    title:
      "It was ok it wasn’t expensive but they could do with a little decorating .",
    comment:
      "Location was good for Wembley Stadium.. 2 stops away .. if you are just looking for somewhere to stay for the night and aren’t too fussy it’s ok.Smell of weed in room .. net curtains were so dirty .. glass dirty .. but bed linen was clean",
    overallStar: 15,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 49,
    name: "Megan",
    country: "United Kingdom",
    roomId: 248,
    nights: 1,
    travelerType: "Group",
    reviewDate: "2024-06-23",
    reviewerChoice: false,
    title: "Simple, clean, doable for a night.",
    comment:
      "40 minute walk to Wembley/15 min taxi. Clean spacious rooms with ensuite.Added breakfast the day of checking in & was charged for room & breakfast in one go, wasn't given anything to show this. Went down in the morning and the chef wouldn't let us eat and they hadn't made note of us adding the breakfast. Went to reception and the man couldn't understand what I was trying to say, took 15 minutes of back and forth to get our tickets and even then he tried to charge me again.",
    overallStar: 14,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 50,
    name: "Johnny",
    country: "Ireland",
    roomId: 249,
    nights: 3,
    travelerType: "Family",
    reviewDate: "2024-06-22",
    reviewerChoice: false,
    title: "Reasonable for a short stay but not for family and comfort",
    comment:
      "Staffs were so friendly and helpful, location is good. Was easy to find the hotel.For a 3 star hotel it was fairly poor. Beds and the rooms looked so old and bit dirty walls and floor. Bathroom was not too bad. But room was very small.",
    overallStar: 15,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 51,
    name: "Ian",
    country: "United Kingdom",
    roomId: 250,
    nights: 3,
    travelerType: "Family",
    reviewDate: "2024-06-21",
    reviewerChoice: false,
    title: "Lovely hotel and staff but unfortunate about the Bed",
    comment:
      "Friendly Hotel and Bar Area.I was Bitted a few times in the bed at Night leaving blister type bite all down my side and on my head which is consistent with Bed bugs",
    overallStar: 15,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 52,
    name: "Iyarthevan",
    country: "United Kingdom",
    roomId: 251,
    nights: 1,
    travelerType: "Couple",
    reviewDate: "2024-06-21",
    reviewerChoice: false,
    title:
      "It is one night therefore I cannot say much the name of hotel not display very well",
    comment:
      "Bathroom and bedroom carpet not clean or maintenance up to hygiene standards Bedroom carpet",
    overallStar: 12,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 53,
    name: "Saida",
    country: "United Kingdom",
    roomId: 252,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2024-06-18",
    reviewerChoice: false,
    title:
      "close to return the rented car a supermarket on the corner to buy something to eat. Affordable",
    comment:
      "The size of the room were fine, the breakfast had many options. we liked it the smell on the stairs",
    overallStar: 12,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 54,
    name: "Charlotte",
    country: "United Kingdom",
    roomId: 253,
    nights: 2,
    travelerType: "Group",
    reviewDate: "2024-06-18",
    reviewerChoice: false,
    title:
      "Cheap, kind of cheerful, but would recommend if you're looking for a close and cheap stay to Wembley",
    comment:
      "The hotel met expectations. It was a quiet room in a good location, close to Wembley. The breakfast was okay, the hot food selection was okay. Each receptionist we met, and members of staff in general, were pleasant.The room/decoration was very outdated. It felt like we'd been thrown back into the 60s. The beds were single beds, which we expected, however the pillows and duvet were very thin. There was mould in our shower, which prevented us from using it. The toilet struggled with the simplest of flushes, it left tissue down there. The cold food options for breakfast weren't great and there were no jugs of water out during breakfast.",
    overallStar: 13,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 55,
    name: "John",
    country: "United Kingdom",
    roomId: 254,
    nights: 1,
    travelerType: "Family",
    reviewDate: "2024-06-10",
    reviewerChoice: false,
    title: "It served our purpose…",
    comment:
      "Room was very hot and stank of smoke.Breakfast was poor…awful sausages!",
    overallStar: 14,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 56,
    name: "Paul",
    country: "United Kingdom",
    roomId: 255,
    nights: 1,
    travelerType: "Group",
    reviewDate: "2024-06-10",
    reviewerChoice: false,
    title: "Passable",
    comment:
      "Staff were very helpful ...allowed us to park our motorbikes in the courtyard 🙂 beer and football ⚽ on TV in lounge..... all staff very good 👍Tiny room,,,,....and decor needed attention...bedding was fine..",
    overallStar: 12,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 57,
    name: "Diane",
    country: "United Kingdom",
    roomId: 256,
    nights: 1,
    travelerType: "Couple",
    reviewDate: "2024-06-10",
    reviewerChoice: false,
    title: "Decent stay",
    comment: "Basic room, but the location was convenient.",
    overallStar: 15,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 58,
    name: "Slartibart",
    country: "United Kingdom",
    roomId: 257,
    nights: 2,
    travelerType: "Solo",
    reviewDate: "2024-06-06",
    reviewerChoice: false,
    title: "Pleasant",
    comment:
      "English breakfast, large common areas, good price Far away from the centre (although there is a bus stop right in front and a metro station at walking distance), slightly run down (e.g. teared curtain in my room, tv did not work)",
    overallStar: 15,
    helpful: 2,
    notHelpful: 0,
  },
  {
    reviewId: 59,
    name: "Graham",
    country: "United Kingdom",
    roomId: 258,
    nights: 3,
    travelerType: "Solo",
    reviewDate: "2024-05-27",
    reviewerChoice: false,
    title: "Pleasant",
    comment:
      "2nd room was fine, location great for work, breakfast was good once it was ready, a little late on all 3 mornings late breakfast, 15 mins earlier would have been ideal.",
    overallStar: 10,
    helpful: 1,
    notHelpful: 0,
  },
  {
    reviewId: 60,
    name: "Boxy",
    country: "United Kingdom",
    roomId: 259,
    nights: 2,
    travelerType: "Family",
    reviewDate: "2024-05-27",
    reviewerChoice: false,
    title: "Pleasant",
    comment:
      "It was close to Wembley Stadium The price, expensive for a very basic room, no coathangars, no plug for sink, breakfast was cold, and coffee only warm,",
    overallStar: 11,
    helpful: 2,
    notHelpful: 0,
  },
];

export default reviewData;

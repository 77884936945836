import React, { useState, useEffect } from "react";
import "./SiteMaps.css";
import Card from "../common/Card";
import HomeCard from "../Sitecard/homeCard";
import { Collapse } from "antd";
import { CaretRightOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Label } from "@mui/icons-material";
import { Helmet } from "react-helmet";


const {Panel}= Collapse;



const SiteMaps = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 820px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 820px)").matches);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const expandIcon = ({ isActive }) => {
    return isActive ? (
      <CaretDownOutlined style={{ transform: "rotate(180deg)" }} />
    ) : (
      <CaretRightOutlined />
    );
  };
  const handlePhoneNoClick = (e, phoneNumber) => {
    e.preventDefault();

    navigator.clipboard
      .writeText(phoneNumber)
      .then(() => {
        const { clientX, clientY } = e;
        setTooltipPosition({ x: clientX, y: clientY });
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 2000); // Hide tooltip after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };


  const siteMapData = [
    {
      key: "1",
      title: "Navigation",
      links: [
        { label: "Home", href: "/" },
        { label: "Rooms", href: "/rooms" },
        { label: "Facilities", href: "/facilities" },
        { label: "Meeting and Events", href: "/main-meeting" },
        { label: "Location", href: "/location" },
        { label: "Review", href: "/review" },
      ],
    },
    {
      key: "2",
      title: "Booking",
      links: [
        { label: "Book Now", href: "https://direct-book.com/properties/bridgeparkdirect?" },
        { label: "FAQs", href: "/faq" },
        { label: "Booking Policies", href: "/HomeRules" },
        { label: "Policies", href: "https://direct-book.com/properties/bridgeparkdirect/policies?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&currency=GBP&checkInDate=2024-07-29&checkOutDate=2024-07-30&trackPage=yes" },
        { label: "Guest Info", href: "https://direct-book.com/properties/bridgepark/book?step=step1" },
        { label: "SiteMinder Page", href: "https://direct-book.com/properties/bridgeparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&currency=GBP&checkInDate=2024-06-14&checkOutDate=2024-06-15&trackPage=no" },
      ],
    },
    {
      key: "3",
      title: "Support",
      links: [
        { label: "Website Support", href: "/website-support" },
        { label: "Contact Us", href: "https://direct-book.com/properties/bridgeparkdirect/contact?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&currency=GBP&checkInDate=2024-07-29&checkOutDate=2024-07-30&trackPage=yes" },
        { label: "Privacy Policy", href: "/privacy" },
      ],
    },
    {
      key: "4",
      title: "Social Media",
      links: [
        { label: "Instagram", href: "https://www.instagram.com/bridgeparkhotellondon/" },
        { label: "Facebook", href: "https://www.facebook.com/bridgeparkhotellondon/" },
        { label: "Twitter", href: "https://twitter.com/bridgeparkuk" },
        { label: "LinkedIn", href: "https://www.linkedin.com/company/bridgeparkhotellondon" },
        { label: "Pinterest", href: "https://www.pinterest.com/bridgeparkhotellondon/" },
      ],
    },
    {
      key: "5",
      title: "Location",
      links: [
        { label: "Maps", href: "https://www.google.com/maps?ll=51.541287,-0.261686&z=17&t=m&hl=en&gl=LK&mapclient=embed&cid=11362073612214208868" },
      ],
    },
    {
      key: "6",
      title: "Contact",
      links: [
        { label: "Calls", href: "#", onClick: (e) => handlePhoneNoClick(e, "+44 208961 1260"),  },
        { label: "Official Mail", href: "mailto:bridgeparkhotel@gmail.com" },
        { label: "Other Mail", href: "mailto:bridgeparkhotel@gmail.com" },
      ],
    },
    {
      key: "7",
      title: "About",
      links: [
        { label: "About Us", href: "https://direct-book.com/properties/bridgeparkdirect/about?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&currency=GBP&checkInDate=2024-07-29&checkOutDate=2024-07-30&trackPage=yes" },
      ],
    },
  ];
  

  return (
    <>
    <Helmet>
        <title>Sitemap - Bridge Park Hotel</title>
        <meta name="description" content="Explore the sitemap of Bridge Park Hotel to easily navigate our website. Find links to all important pages including rooms, amenities, and contact information." />
        <meta name="keywords" content="Bridge Park Hotel sitemap, hotel site map, website navigation, hotel pages, site map London hotel" />
        <meta name="author" content="Bridge Park Hotel" />
        <meta name="robots" content="noindex, follow" />
        <meta property="og:title" content="Sitemap - Bridge Park Hotel" />
        <meta property="og:description" content="Navigate Bridge Park Hotel's website effortlessly with our sitemap. Access all key pages including rooms, services, and contact details." />
        <meta property="og:image" content="URL_to_image_of_hotel_or_navigation.jpg" /> {/* Replace with actual URL to an image related to navigation or the hotel */}
        <meta property="og:url" content="https://www.bridgeparkhotel.co.uk/sitemaps" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.bridgeparkhotel.co.uk/sitemaps" />
      </Helmet>
      <div className="SiteMaps">
        <div className="SiteMaps-main">
          {isMobile ? (
       <div className="location-sitecard"><HomeCard /></div>
          ) : (
            <div className="SiteMaps-card">
              <Card />
            </div>
          )}
        </div>
        <div className="SiteMaps-title">Site Maps</div>
        <div className="SiteMaps-sub">
          <div className="SiteMaps-mainContainor">
            <div className="SiteMaps-Containor">
              <div className="SiteMaps-sub">
                <h1 className="SiteMaps-sub-title">Pages</h1>
              </div>
              
              <div className="sitemap-content">
        <Collapse
          accordion
          className="sitemap-collapse"
          expandIconPosition="right"
          expandIcon={expandIcon}
        >
          {siteMapData.map(({ key, title, links }) => (
            <Panel header={title} key={key} className="SitemapPanel-border">
              <ul>
                {links.map((link, index) => (
                  <li key={index}>
                    {link.onClick ? (
            <a href={link.href} onClick={(e) => link.onClick(e)}>
              {link.label}
            </a>
          ) : (
            <a href={link.href} target="_blank" rel="noopener noreferrer">
              {link.label}
            </a>
          )}
                  </li>
                ))}
                 {showTooltip && (
                        <div
                          className="SiteMapstooltip"
                          style={{
                            top: tooltipPosition.y + window.scrollY - 30, 
                            left: tooltipPosition.x + window.scrollX + 10, 
                          }}
                        >
                          Phone number copied to clipboard!
                        </div>
                      )}
              </ul>
             
            </Panel>
          ))}
        </Collapse>
      </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteMaps;

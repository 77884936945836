import React, { useState,  useEffect } from "react";
import { Row, Col, Modal } from "antd";
import "./AllRooms.css";
import RoomInformation from "../home/RoomInformation";
import Images from "../common/Images";
import UrlLib from "../../components/common/UrlLib";
import { useNavigate } from "react-router-dom";
import icon11 from "../../assets/icons/private.jpg"
import icon22 from "../../assets/icons/shared.jpg"
import SinBed from "../../assets/icons/single_bed.png"
import HotelAllCards from "./AllCards";


const RoomAll = ({ selectedCardId, selectedCardTitle }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedRoomId, setSelectedRoomId] = useState(0);
  const [bookingUrl, setBookingUrl] = useState('');
  const [bathroomType, setBathroomType] = useState('');
  const [roomPrice, setRoomPrice] = useState('');
  const [bookLink, setBookLink] = useState('');
  const [count_guest, setCount_guest] = useState('');
  
  const navigate = useNavigate();

  const handleViewMoreClick = (room, url, id) => {
    setSelectedRoom({
      ...room,
      images: room.images || [],
      facilities: room.facilities || [],
      icon: room.icon,
    });
    setBookingUrl(url);
    setIsModalVisible(true);
    setSelectedRoomId(id)
    setRoomPrice(room.roomPrice);
    setBookLink(room.bookLink);
    setCount_guest(room.count_guest)
    if (room.title.includes('SHARED')) {
      setBathroomType('Shared');
    } else {
      setBathroomType('Private');
    }
  };
  useEffect(() => {
  }, [selectedCardId, selectedCardTitle]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getUrlById = (id) => {
    const urlObject = UrlLib.find(url => url.id === id);
    return urlObject ? urlObject.url : '#';
  };


  const roomData = [
    {
      id: 28,
      image: Images.room1[11],
      title: "SINGLE ROOM WITH SHARED BATHROOM",
      cardTitle: "1 x SINGLE BED",
      roomPrice: "£50",
      vat:"10",
      icon:icon22,
      NoOfGuests: "01",
      NoOfRooms: "01",
      images: Images.room1,
      bedIcons: [SinBed],
      cardId: "01",
      Disc:"Contemporary room featuring a single bed and shared bathroom facilities.Located less than a 10-minute travel to Wembley Stadium, it provides easy access to various transport links around London. Includes tea and coffee making facilities, TV with free-view, and free WiFi.",
      BookingLink:"31929",
      facilities: ["Free WiFi","Non-smoking rooms", "Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],

    
    },
    {
      id: 29,
      image: Images.room2[1],
      title: "DOUBLE ROOM WITH SHARED BATHROOM",
      cardTitle: "1 x DOUBLE BED",
      imageUrl: getUrlById(5),
      roomPrice: "£63",
      vat:"12.6",
      icon:icon22,
      NoOfGuests: "02",
      images: Images.room2,
      cardId: "02",
      Disc:"Standard double room featuring a double bed and shared bathroom facilities. Conveniently located less than a 10-minute travel to Wembley Stadium, this room includes tea and coffee making facilities, TV with free-view, and free WiFi.",
      BookingLink:"31921",
      facilities: ["Free WiFi","Non-smoking rooms", "Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],

    },
    {
      id: 29,
      image: Images.room5[0],
      title: "DOUBLE ROOM WITH PRIVATE BATHROOM",
      cardTitle: "1 x DOUBLE BED",
      roomPrice: "£73",
      vat:"14.6",
      NoOfGuests: "02",
      NoOfRooms: "01",
      icon:icon11,
      images: Images.room5,
      cardId: "02",
      Disc:" Spacious and contemporary room with a comfortable double bed and a private bathroom. Situated less than a 10-minute travel to Wembley Stadium, it offers easy access to various transport links around London. Features include tea and coffee making facilities, TV with free-view, and free WiFi.",
       BookingLink:"31920",
       facilities: ["Free WiFi","Non-smoking rooms","Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],

    },
    
    {
      id: 34,
      image: Images.room3[2],
      title: "TWIN ROOM WITH SHARED BATHROOM",
      cardTitle: "2 x SINGLE BEDS",
      roomPrice: "£68",
      vat:"13.6",
      NoOfGuests: "02",
      NoOfRooms: "01",
      icon:icon22,
      images: Images.room3,
      cardId: "03",
      Disc:"Standard twin room with two single beds and shared bathroom facilities. Positioned less than a 10-minute travel to Wembley Stadium, this room provides easy access to various transport links around London. Includes tea and coffee making facilities, TV with free-view, and free WiFi.",
      BookingLink:"31933",
      facilities: ["Free WiFi","Non-smoking rooms", "Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],
    },
    {
      id: 11,
      image: Images.room6[0],
      title: "TWIN ROOM WITH PRIVATE BATHROOM",
      cardTitle: "2 x SINGLE BEDS",
      roomPrice: "£78",
      vat:"15.6",
      NoOfGuests: "02",
      icon:icon11,
      images:Images.room6,
      cardId: "03",
      Disc:"Spacious twin room with two single beds and private en-suite bathroom facilities. Conveniently located less than a 10-minute travel to Wembley Stadium, this room features contemporary amenities including tea and coffee making facilities, TV with free-view, and free WiFi.",
      cardId:"03",
      BookingLink:"31934",
      facilities: ["Free WiFi","Non-smoking rooms","Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],

    },
   
    {
      id: 31,
      image: Images.room4[2],
      title: "TRIPLE ROOM WITH SHARED BATHROOM",
      cardTitle: "3 x SINGLE BEDS",
      roomPrice: "£90",
      vat:"18",
      NoOfGuests: "03",
      icon:icon22,
      images: Images.room4,
      cardId: "04",
      Disc:"Spacious room featuring three single beds and shared bathroom facilities. Conveniently located, this room includes tea and coffee making facilities, TV with free-view, and free WiFi. Table and chairs are also available.",
      BookingLink:"31931",
      facilities: ["Free WiFi","Non-smoking rooms", "Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],
    },
    {
      id: 11,
      image: Images.room7[0],
      title: "TRIPLE ROOM WITH PRIVATE BATHROOM",
      cardTitle: "3 x SINGLE BEDS",
      imageUrl: getUrlById(3),
      roomPrice: "£100",
      vat:"20",
      NoOfGuests: "03",
      NoOfRooms: "01",
      images: Images.room7,
      bedIcons: [SinBed, SinBed],
      cardId: "04",
      Disc:" Spacious room with three single beds and private en-suite bathroom facilities. This room includes tea and coffee making facilities, TV with free-view, and free WiFi. Table and chairs are also available.",
      BookingLink:"31927",
      facilities: ["Free WiFi","Non-smoking rooms", 'Family rooms',"Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],
      icon:icon11,
    },

    {
      id: 30,
      image: Images.room8[0],
      title: "TRIPLE ROOM WITH PRIVATE BATHROOM",
      cardTitle: "1 x DOUBLE BED + 1 x SINGLE BED",
      roomPrice: "£105",
      vat:"21",
      NoOfGuests: "03",
      icon:icon11,
      images: Images.room8,
      cardId: "04",
      Disc:"This spacious room features one double bed and one single bed with private en-suite bathroom facilities. Located less than a 10-minute travel to Wembley Stadium, it offers easy access to various transport links around London. Includes tea and coffee making facilities, TV with free-view, and free WiFi. Table and chairs are available.",
       BookingLink:"31932",
       facilities: ["Free WiFi","Non-smoking rooms", 'Family rooms',"Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],

    },
    {
      id: 33,
      image: Images.room10[3],
      title: "QUADRUPLE ROOM WITH PRIVATE BATHROOM",
      cardTitle: "1 x DOUBLE BED + 1 x BUNK BED",
      imageUrl: getUrlById(10),
      roomPrice: "£110",
      icon:icon11,
      vat:"22",
      NoOfGuests: "04",
      images:Images.room10,
      cardId: "05",
      Disc:"Spacious room with one double bed and a bunk bed, featuring private en-suite bathroom facilities. Located less than a 10-minute travel to Wembley Stadium, this room offers easy access to various transport links around London. Includes tea and coffee making facilities, TV with free-view, and free WiFi. Table and chairs are available.",
      BookingLink:"31925",
      facilities: ["Free WiFi","Non-smoking rooms",'Family rooms',"Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],
    },
     {
      id: 10,
      image: Images.room9[1],
      title: "QUADRUPLE ROOM WITH PRIVATE BATHROOM",
      cardTitle: "1 x DOUBLE BED + 2 x SINGLE BEDS",
      roomPrice: "£125",
      vat:"25",
      NoOfGuests: "04",
      NoOfRooms: "01",
      icon:icon11,
      images: Images.room9,
      cardId: "05",
      Disc:" Spacious room featuring one double bed and two single beds with private en-suite bathroom facilities. Conveniently located, this room includes tea and coffee making facilities, TV with free-view, and free WiFi. Table and chairs are also available.",
       BookingLink:"31925",
       facilities: ["Free WiFi","Non-smoking rooms", 'Family rooms',"Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],

    },
    {
      id: 5,
      image:  Images.room11[4],
      title: "EXECUTIVE KING ROOM WITH PRIVATE BATHROOM",
      cardTitle: "1 x LARGE DOUBLE BED + 1 x SOFA BED",
      roomPrice: "£115",
      vat:"23",
      NoOfGuests: "03",
      NoOfRooms: "01",
      icon:icon11,
      images: Images.room11,
      cardId: "06",
      Disc:" Stunningly spacious room featuring a luxurious king-sized bed and a sofa bed, with private en-suite bathroom facilities. This newly decorated suite at The Bridge Park Hotel offers a blend of elegance and comfort, ideal for special occasions. Includes tea and coffee making facilities, TV with free-view, and free WiFi. Additional amenities include air conditioning, bathrobes, and a mini fridge.",
      BookingLink:"448426",
      facilities: ["Free WiFi","Non-smoking rooms", 'Family rooms',"Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],

    },
    {
      id: 4,
      image: Images.room12[1],
      title: "CHAMBRE OF TWILIGHT ROOM WITH PRIVATE BATHROOM",
      cardTitle: "1 x SUPERIOR KING BED",
      roomPrice: "£120",
      vat:"24", 
      NoOfGuests: "02",
      icon:icon11,
      images:Images.room12,
      cardId: "06",
      Disc:"Stunningly spacious room featuring a luxurious superior king bed and private en-suite bathroom facilities. This newly decorated suite at The Bridge Park Hotel offers a blend of elegance and comfort, ideal for special occasions. Includes tea and coffee making facilities, TV with free-view, and free WiFi. Additional amenities include air conditioning, bathrobes, and a mini fridge.",
      BookingLink:"",
      facilities: ["Free WiFi","Non-smoking rooms", 'Family rooms',"Daily housekeeping", 'Free parking','24-hour front desk','Bar','Heating','Breakfast'],
    },  
   
  ];
  const filteredRoomData = roomData.filter(
    (room) => room.cardId === selectedCardId
  );

  return (
    <div>
      <div>
        <div>
          <div className='location-title-ALL'>{selectedCardTitle}</div>
        </div>
      </div>
      <div className="hotelRoomMainDiv-ALL">
        <Row gutter={[16, 16]} className="hotelRoom-row-ALL">
          {filteredRoomData.map((room) => (
            <Col key={room.id} className="hotelRoom-col-ALL">
              <HotelAllCards
                imageSource={room.image}
                title={room.title}
                status="Check"
                price={room.roomPrice}
                buttonText="Book Now"
                cardtitle={room.cardTitle}
                NoOfGuests={room.NoOfGuests}
                NoOfRooms={room.NoOfRooms}
                bedIcons={room.bedIcons}
                onViewMoreClick={() => handleViewMoreClick({
                  title: room.title,
                  cardTitle: room.cardTitle,
                  id: room.id,
                  images: room.images,
                  disc:room.Disc,
                  Vat: room.vat,
                  roomPrice: room.roomPrice,
                  bookLink:room.BookingLink,
                  count_guest:room.NoOfGuests,
                  facilities: room.facilities || [] ,
                }, room.imageUrl, room.id)}
                link={room.imageUrl}
              />
            </Col>
          ))}
        </Row>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="73%"
      >
        {selectedRoom && (
          <>
            <RoomInformation
              room={selectedRoom}
              bookingUrl={bookingUrl}
              selectedRoomId={selectedRoomId}
              bathroomType={bathroomType}
              roomPrice={roomPrice}
              bookLink={bookLink}
              count_guest={count_guest}
              icon={selectedRoom.icon}

            />
          </>
        )}
      </Modal>
    </div>
  );
};

export default RoomAll;

import React from "react";
import RoomType from "../components/home/RoomType";
import "../css/RoomType/Room.css";
import bgimage from "../assets/bph-welcome.webp";
import { Helmet } from "react-helmet";

const Rooms = () => {
  return (
    <>
      <Helmet>
        <title>Luxury Budget Rooms in London - Bridge Park Hotel</title>
        <meta name="description" content="Discover affordable luxury at Bridge Park Hotel in London. Our Luxury Budget Rooms offer elegant accommodations without breaking the bank. Book your stay today!" />
        <meta name="keywords" content="Luxury Budget Room London, affordable luxury hotel London, budget-friendly luxury hotel London, Bridge Park Hotel rooms, luxury hotel rooms London, budget luxury accommodation London" />
        <meta name="author" content="Bridge Park Hotel" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Luxury Budget Rooms in London - Bridge Park Hotel" />
        <meta property="og:description" content="Experience the best of both worlds with our Luxury Budget Rooms at Bridge Park Hotel. Enjoy elegant, affordable accommodations in London." />
        <meta property="og:image" content="URL_to_image_of_room.jpg" /> {/* Replace with actual URL to an image of the room */}
        <meta property="og:url" content="https://bridgeparkhotel.co.uk/rooms" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://bridgeparkhotel.co.uk/rooms" />
      </Helmet>
      <div className="RoomsTypeFirstAlignment">
        <img className='room-bgimage' src={bgimage} alt="Room Background" />
        <RoomType />
      </div>
    </>
  );
};

export default Rooms;
